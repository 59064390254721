// hooks/useEmailVerification.js
import { useState } from 'react';
import EmailAuthService from '../service/EmailAuthService'; // Yolunuz doğru olmalı

function useEmailVerification() {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const sendEmail = async (emailInput) => {
    try {
      await EmailAuthService.sendEmail(emailInput);
      setIsOtpSent(true);
      setEmail(emailInput); // E-posta adresini state'te sakla
      alert('OTP kodu e-mailinize gönderildi.');
    } catch (error) {
      console.error('E-mail gönderilirken bir hata oluştu:', error);
      alert('E-mail gönderilirken bir hata oluştu.');
    }
  };

  const verifyOTP = async (otpInput) => {
    try {
      const response = await EmailAuthService.verifyOTP(email, otpInput);
      setOtp(otpInput); // OTP'yi state'te sakla
      return response; // Token içeren response'u döndür
    } catch (error) {
      console.error('OTP doğrulaması sırasında bir hata oluştu:', error);
      alert('OTP doğrulaması sırasında bir hata oluştu.');
      return null; // Hata durumunda null döndür
    }
  };

  return { isOtpSent, sendEmail, verifyOTP, setEmail, setOtp, email, otp };
}

export default useEmailVerification;
