import { useEffect, useState } from "react";
import "./TabGroups.css"; // CSS dosyasında TabGroups ve TabsButton için stiller olduğundan emin olun

const TabGroups = ({ data, setLabel, Index }) => {
  const [activeButton, setActiveButton] = useState(Index || 0); // Başlangıç için 0'ı kullan

  useEffect(() => {
    // activeButton değiştiğinde label güncellenir
    if (setLabel !== undefined) {
      setLabel(data[activeButton].label);
    }
  }, [activeButton, setLabel, data]);

  // Düğme tıklamasını ele al
  const handleClick = (id) => {
    setActiveButton(id);
  };

  return (
    <div className="tab-groups">
      {data.map((button, index) => (
        <button
          key={index}
          className={`tabs-button ${activeButton === index ? 'active' : ''}`}
          onClick={() => handleClick(index)}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default TabGroups;
