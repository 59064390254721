import React, { useEffect, useRef, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();
  const { exchange, currency } = useParams();
  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  
  const tradeToken = useMemo(() => ({
    buyPrice: queryParams.get("buyPrice"),
    sellPrice: queryParams.get("sellPrice"),
    buyVolume: queryParams.get("buyVolume"),
    sellVolume: queryParams.get("sellVolume"),
    amount: queryParams.get("amount"),
    logoURI: queryParams.get("logoURI"),
    contractAddress: queryParams.get("contractAddress"),
    jup: queryParams.get("jup"),
    currencyA: currency.split('_')[0],
    stockA: exchange,
  }), [queryParams, currency, exchange]);

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      let stock = "";
      if (tradeToken.stockA === "Jupiter") {
        stock = tradeToken.stockB;
      } else {
        stock = tradeToken.stockA;
      }

      if (
        document.getElementById("tradingview_fec01") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          width: "100%",
          height: "60%",
          symbol: `${stock === "Gate" ? "Gateio" : stock}:${tradeToken.currencyA}USDT`,
          interval: "5",
          timezone: "Etc/UTC",
          theme: "light",
          style: "8",
          locale: "en",
          enable_publishing: false,
          allow_symbol_change: true,
          show_popup_button: true,
          popup_width: "1000",
          popup_height: "650",
          hide_volume: true,
          container_id: "tradingview_fec01",
        });
      }
    }
  }, [tradeToken]);

  return (
    <div className="tradingview-widget-container mobile-hidden">
      <div id="tradingview_fec01" />
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
}
