import { useEffect, useState } from 'react';
import IPAuthService from '../service/IPAuthService';

const useIPCheck = () => {
  const [status, setStatus] = useState({ isAllowed: false, isLoading: true });

  useEffect(() => {
    const verifyIP = async () => {
      const isAllowed = await IPAuthService.checkIP();
      setStatus({ isAllowed, isLoading: false });
    };

    verifyIP();
  }, []);

  return status;
};


export default useIPCheck;