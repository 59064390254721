import React, { useState, useMemo } from "react";
import "./Wallet.css";
import { useApp } from "../../context/AppContext";
import BalanceTable from "../../components/BalanceTable/BalanceTable";

const Wallet = () => {
  const { balance } = useApp();
  const [searchTerm, setSearchTerm] = useState("");

  // Veriyi available objesine göre büyükten küçüğe sıralamak
  const sortedData = useMemo(() => {
    return balance.sort((a, b) => b.available - a.available);
  }, [balance]);

  // Arama terimine göre filtreleme
  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
      item.currency.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, sortedData]);

  return (
    <div className="wallet-container">
      <div className="wallet-left">
        <div>
          <h1>Cüzdan</h1>
          <input
            type="text"
            placeholder="Arama yap..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <BalanceTable data={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default Wallet;
