import "./Loading.css";

const Loading = () => {
  return (
    <div className="load-app">
      <div className="load-container">
        <div className="loading-indicator">
          <div className="loading-arrow"></div>
        </div>
        <span className="loading-span">Yarinin dunyasi bu</span>
      </div>
    </div>
  );
};

export default Loading;
