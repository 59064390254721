import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getService } from "../service/ServiceManager";
import { useAuth } from "./AuthContext";
import axios from 'axios';

const AppContext = createContext();
export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const { isWebSocketOpen } = useAuth();
  const initialExchange = localStorage.getItem("selectedExchange") || "Gate";
  const initialFilterGroups = localStorage.getItem("filterGroups") || "Jupiter";
  const initialSelectedOption = localStorage.getItem("selectedOption") || "Hepsi";
  const [selectedExchange, setSelectedExchange] = useState(initialExchange);
  const [service, setService] = useState(() => getService(selectedExchange));
  const [tokens, setTokens] = useState([]);
  const [balance, setBalance] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [publicTrades, setPublicTrades] = useState([]);
  const [orderBook, setOrderBook] = useState([]);
  const [tradeToken, setTradeToken] = useState({});
  const [jupiterSend, setJupiterSend] = useState({});
  const [walletToken, setWalletToken] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const [apiKey, setApiKey] = useState(localStorage.getItem(`${selectedExchange}-Key`));
  const [apiSecret, setApiSecret] = useState(localStorage.getItem(`${selectedExchange}-Secret`));
  const [filterGroups, setFilterGroups] = useState(initialFilterGroups);
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [arbitrageTable, setArbitrageTable] = useState([]);
  const [defaultSymbol, setDefaultSymbol] = useState([]);
  const [defaultSymbolV2, setDefaultSymbolV2] = useState([]);
  const [exchangeGroups, setExchangeGroups] = useState([
    { label: "Jupiter" },
    { label: "Gate" },
    { label: "Mexc" },
    { label: "Kucoin" },
    { label: "Bybit" },
  ]);
  const [notificationValue, setNotificationValue] = useState(localStorage.getItem('notificationValue') || '');
  const [soundAlarmValue, setSoundAlarmValue] = useState(localStorage.getItem('soundAlarmValue') || '');
  const [soundAlarmActive, setSoundAlarmActive] = useState(JSON.parse(localStorage.getItem('soundAlarmActive')) || false);
  const [notificationAlarmActive, setNotificationAlarmActive] = useState(JSON.parse(localStorage.getItem('notificationAlarmActive')) || false);
  const [isAlarmPlaying, setIsAlarmPlaying] = useState(false);

  const audio = useRef(new Audio('/alarm.mp3'));

  const telegramBotToken = "7399607181:AAGg-jt5cUCfsCXCRHrbMQq8VI6GjS88P_I"; // Telegram bot token'ınızı buraya ekleyin
  const telegramChatId = "-4159233724"; // Mesaj göndermek istediğiniz chat id'yi buraya ekleyin

  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initialTradeToken = {
      currencyA: searchParams.get("currencyA"),
      stockA: searchParams.get("stockA"),
      sellPrice: searchParams.get("sellPrice"),
      sellVolume: searchParams.get("sellVolume"),
      amount: searchParams.get("amount"),
      logoURI: searchParams.get("logoURI"),
      contractAddress: searchParams.get("contractAddress"),
      jup: searchParams.get("jup"),
    };

    if (initialTradeToken.currencyA && initialTradeToken.stockA) {
      setTradeToken(initialTradeToken);
    }
  }, [location.search, searchParams]);

  useEffect(() => {
    localStorage.setItem('notificationValue', notificationValue);
  }, [notificationValue]);

  useEffect(() => {
    localStorage.setItem('soundAlarmValue', soundAlarmValue);
  }, [soundAlarmValue]);

  useEffect(() => {
    localStorage.setItem('soundAlarmActive', JSON.stringify(soundAlarmActive));
  }, [soundAlarmActive]);

  useEffect(() => {
    localStorage.setItem('notificationAlarmActive', JSON.stringify(notificationAlarmActive));
  }, [notificationAlarmActive]);

  useEffect(() => {
    if (soundAlarmActive && arbitrageTable.length > 0) {
      arbitrageTable.forEach((item) => {
        if (item.profit > parseFloat(soundAlarmValue) && !isAlarmPlaying) {
          audio.current.play();
          setIsAlarmPlaying(true);
        }
      });
    } else {
      audio.current.pause();
      audio.current.currentTime = 0;
      setIsAlarmPlaying(false);
    }
  }, [arbitrageTable, soundAlarmActive, soundAlarmValue, isAlarmPlaying]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notificationAlarmActive && arbitrageTable.length > 0) {
        arbitrageTable.forEach((item) => {
          if (item.profit > parseFloat(notificationValue) && parseFloat(item.buyPrice) > 0 && parseFloat(item.sellPrice) > 0) {
            const minVolume = Math.min(item.buyVolume, item.sellVolume);
            const minPrice = Math.min(parseFloat(item.buyPrice), parseFloat(item.sellPrice));
            const message = `FrontEnd Bot !!!\nProfit Alert!\nBuy: ${item.currencyA} ${item.stockA} \nSell: ${item.currencyB} ${item.stockB}\nBuy Price: ${item.buyPrice}\nSell Price: ${item.sellPrice}\nVolume: ${minVolume}\nVolume USD: ${parseInt(minVolume * minPrice)}\nProfit : ${item.profit}`;
            sendTelegramMessage(message);
          }
        });
      }
    }, 60000); // Her dakika kontrol et

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [arbitrageTable, notificationAlarmActive, notificationValue]);

  const stopSoundAlarm = () => {
    audio.current.pause();
    audio.current.currentTime = 0;
    setIsAlarmPlaying(false);
  };

  const sendTelegramMessage = (message) => {
    const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;
    axios.post(url, {
      chat_id: telegramChatId,
      text: message
    }).then(response => {
      console.log('Message sent:', response.data);
    }).catch(error => {
      console.error('Error sending message:', error);
    });
  };

  const urlMap = {
    Gate: "https://gate.gangue.io/images/",
    Mexc: "https://mexc.gangue.io/images/",
    Jupiter: "https://jup.gangue.io/images/",
  };

  useEffect(() => {
    setService(getService(selectedExchange));
  }, [selectedExchange]);

  useEffect(() => {
    setApiKey(localStorage.getItem(`${selectedExchange}-Key`));
    setApiSecret(localStorage.getItem(`${selectedExchange}-Secret`));
  }, [selectedExchange]);

  useEffect(() => {
    localStorage.setItem("selectedExchange", selectedExchange);
    localStorage.setItem("filterGroups", filterGroups);
    localStorage.setItem("selectedOption", selectedOption);
  }, [selectedExchange, filterGroups, selectedOption]);

  useEffect(() => {
    if (isWebSocketOpen) {
      service.Token.retrieveTokens()
        .then(setTokens)
        .catch((error) => {
          console.error("Token bilgileri alınırken hata oluştu:", error);
        });
    }
  }, [service, setTokens, isWebSocketOpen]);

  return (
    <AppContext.Provider
      value={{
        selectedExchange,
        setSelectedExchange,
        service,
        exchangeGroups,
        setExchangeGroups,
        arbitrageTable,
        setArbitrageTable,
        filterGroups,
        setFilterGroups,
        selectedOption,
        setSelectedOption,
        tokens,
        setTokens,
        balance,
        setBalance,
        apiKey,
        setApiKey,
        apiSecret,
        setApiSecret,
        withdrawHistory,
        setWithdrawHistory,
        depositHistory,
        setDepositHistory,
        tradeToken,
        setTradeToken,
        walletToken,
        setWalletToken,
        urlMap,
        orders,
        setOrders,
        tickers,
        setTickers,
        publicTrades,
        setPublicTrades,
        orderBook,
        setOrderBook,
        defaultSymbol, setDefaultSymbol,
        defaultSymbolV2, setDefaultSymbolV2,
        jupiterSend, setJupiterSend,
        notificationValue,
        setNotificationValue,
        soundAlarmValue,
        setSoundAlarmValue,
        soundAlarmActive, 
        setSoundAlarmActive,
        notificationAlarmActive,
        setNotificationAlarmActive,
        stopSoundAlarm // stopSoundAlarm fonksiyonunu ekleyin
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
