import { Roundicon } from "../../assets/icons/icon";
import React, { useState } from "react";
import Select from "../../components/Select/Select";

const Swap = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setOriginalPosition({
      x: e.clientX - translate.x,
      y: e.clientY - translate.y,
    });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setTranslate({
        x: e.clientX - originalPosition.x,
        y: e.clientY - originalPosition.y,
      });
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const exampleData = [
    {
      src: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png",
      symbol: "BTC",
      name: "Bitcoin",
      amount: "0.001",
      value: "$40.00",
    },
    {
      src: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png",
      symbol: "ETH",
      name: "Ethereum",
      amount: "0.05",
      value: "$150.00",
    },
    {
      src: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
      symbol: "SOL",
      name: "Solana",
      amount: "1",
      value: "$33.00",
    },
  ];

  const [selectedToken1, setSelectedToken1] = useState(exampleData[0]);
  const [selectedToken2, setSelectedToken2] = useState(exampleData[1]);

  const handleSwapTokens = () => {
    setSelectedToken1(selectedToken2);
    setSelectedToken2(selectedToken1);
  };
  

  return (
    <div
      className="swap-popup"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Eğer kullanıcı komponentin dışına çıkarsa sürüklemeyi bitir
      style={{
        transform: `translate(${translate.x}px, ${translate.y}px)`,
      }}
    >
      <div className="swap-container">
        <div className="input-box">
          <Select
            data={exampleData}
            onTokenSelect={setSelectedToken1}
            disabledToken={selectedToken2}
            selectedToken={selectedToken1}
          />
          <input className="swap-input" type="number" />
        </div>
        <div className="round-content">
          <span onClick={handleSwapTokens} className="round-span">
            {Roundicon}
          </span>
        </div>
        <div className="input-box">
          <Select
            data={exampleData}
            onTokenSelect={setSelectedToken2}
            disabledToken={selectedToken1}
            selectedToken={selectedToken2}
          />
          <input className="swap-input" type="number" />
        </div>
        <button className="swap-button">Swap</button>
      </div>
    </div>
  );
};

export default Swap;
