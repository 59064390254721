import React from "react";

import "./History.css";


const History = () => {


  return (
    <div className="auth-container">
      History
    </div>
  );
};

export default History;
