import React, { useEffect, useState } from "react";
import defaultStyles from "./TokenListesiTable.module.css";

const ContextMenu = ({ xPos, yPos, onClose, onDelete, onSyncContract }) => {
  useEffect(() => {
    const handleWindowClick = (event) => {
      // ContextMenu'nun kendisi veya çocukları tıklanmadıysa menüyü kapat
      if (!event.target.closest(`.${defaultStyles.contextMenu}`)) {
        onClose(); // Sadece menü dışına tıklandığında menüyü kapat
      }
    };

    // Pencere genelinde tıklama olayını dinle
    window.addEventListener("click", handleWindowClick);
    return () => window.removeEventListener("click", handleWindowClick);
  }, [onClose]);

  return (
    <div
      className={defaultStyles.contextMenu}
      style={{ top: `${yPos}px`, left: `${xPos}px`, position: "absolute", zIndex: 1000 }}
    >
      <div
        className={defaultStyles.contextMenuItem}
        onClick={() => {
          onDelete();
          onClose();
        }}  // Sil butonuna tıklandığında silme işlemini gerçekleştir ve menüyü kapat
      >
        Sil
      </div>
      {onSyncContract && (
        <div
          className={defaultStyles.contextMenuItem}
          onClick={() => {
            onSyncContract();
            onClose();
          }}  // Contract adresini senkronize etme işlemini gerçekleştir ve menüyü kapat
        >
          Contract Adresini Senkronize Et
        </div>
      )}
    </div>
  );
};

const TokenListesiTable = ({
  data,
  updateToken,
  removeToken,
  retrieveTokens,
  syncContract,
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
}) => {
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editableField, setEditableField] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [contextMenu, setContextMenu] = useState(null);

  const handleDoubleClick = (rowIndex, field, value) => {
    setEditableRowIndex(rowIndex);
    setEditableField(field);
    setEditedValue(value);
  };

  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleInputBlur = async (row, field) => {
    if (editedValue !== row[field]) {
      const updatedRow = {
        currency: row.currency,
        chainName: row.chainName,
        [field]: editedValue,
      };

      console.log("Güncellenen veri gönderiliyor:", updatedRow);

      try {
        await updateToken(updatedRow);
        row[field] = editedValue;
        retrieveTokens(); // Hemen güncelleme işlemi sonrasında yenile
        console.log("Güncelleme başarılı:", row);
      } catch (error) {
        console.error("Güncelleme sırasında hata oluştu:", error);
      }
    } else {
      console.log("Değişiklik yapılmadı, güncelleme yapılmayacak.");
    }

    setEditableRowIndex(null);
    setEditableField(null);
  };

  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString('tr-TR');
    const timePart = date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
    return `${datePart} \n ${timePart}`;
  };

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setContextMenu({
      xPos: event.pageX,
      yPos: event.pageY,
      onDelete: async () => {
        try {
          await removeToken({ currency: row.currency, chainName: row.chainName });
          retrieveTokens(); // Hemen silme işlemi sonrasında yenile
        } catch (error) {
          console.error("Silme işlemi sırasında hata oluştu:", error);
        }
        setContextMenu(null);
      },
      onSyncContract: syncContract
        ? async () => {
            try {
              const contractData = await syncContract(row.currency);
              const updatedRow = {
                currency: row.currency,
                chainName: row.chainName,
                contractAddress: contractData.contract_address,
                decimals: contractData.decimals,
              };
              await updateToken(updatedRow);
              retrieveTokens(); // Hemen senkronizasyon işlemi sonrasında yenile
            } catch (error) {
              console.error("Contract adresi senkronize edilirken hata oluştu:", error);
            }
            setContextMenu(null);
          }
        : null,
      onClose: () => setContextMenu(null),
    });
  };

  return (
    <div className={styles.table}>
      {contextMenu && (
        <ContextMenu
          xPos={contextMenu.xPos}
          yPos={contextMenu.yPos}
          onDelete={contextMenu.onDelete}
          onSyncContract={contextMenu.onSyncContract}
          onClose={contextMenu.onClose}
        />
      )}
      
      <div className={styles.tbody}>
        <div className={styles.tr}>
          <span className={styles.th}>Coin</span>
          <span className={styles.th}>Ağ Adı</span>
          <span className={styles.th}>Yatırma</span>
          <span className={styles.th}>Çekme</span>
          <span className={styles.th}>Çekim Fee</span>
          <span className={styles.th}>Min Çekim</span>
          <span className={styles.th}>Sözleşme</span>
          <span className={styles.th}>LogoURI</span>
          <span className={styles.th}>Decimals</span>
          <span className={styles.th}>Tarih</span>
        </div>
        {data.length > 0
          ? data.map((row, rowIndex) => (
              <div
                className={styles.tr}
                key={rowIndex}
                onClick={() => onRowClick && onRowClick(row, rowIndex)}
                onContextMenu={(event) => handleContextMenu(event, row)}
              >
                {Object.keys(row)
                  .filter(field => field !== "id" && field !=="chain") // 'id' alanını filtrele
                  .map((field, index) => (
                    <span
                      key={index}
                      className={styles.td}
                      onDoubleClick={() =>
                        handleDoubleClick(rowIndex, field, row[field])
                      }
                    >
                      {editableRowIndex === rowIndex &&
                      editableField === field ? (
                        <input
                          type="text"
                          value={editedValue}
                          className={styles.einput}
                          onChange={handleInputChange}
                          onBlur={() => handleInputBlur(row, field)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleInputBlur(row, field);
                            }
                          }}
                          autoFocus
                        />
                      ) : field === "contractAddress" ? (
                        <span className={styles.td} title={row[field]}>{formatAddress(row[field])}</span>
                      ) : field === "updated_at" ? (
                        <span className={styles.td} title={row[field]}>{formatDate(row[field])}</span> 
                      ) : field === "logoURI" ? (
                        <span className={styles.td} title={row[field]}>{formatAddress(row[field])}</span> 
                      ) : (
                        row[field]?.toString()
                      )}
                    </span> 
                  ))}
              </div>
            ))
          : noRowsContent}
      </div>
    </div>
  );
};

export default TokenListesiTable;
