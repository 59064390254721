import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./Trading.css";

import TradingViewWidget from "../../components/TradingViewWidget";
import TabGroups from "../../components/TabGroups/TabGroups";
import TradingLeft from "./TradingLeft";
import TradingTrade from "./TradingTrade";
import TradingHeader from "./TradingHeader";
import { useApp } from "../../context/AppContext";
import OpenOrdersTable from "../../components/OpenOrdersTable/OpenOrdersTable";
import PublickTradeTable from "../../components/PublickTradeTable/PublickTradeTable";
import OrderBookTable from "../../components/OrderBookTable/OrderBookTable";
import OrderHistoryTable from "../../components/OrderHistoryTable/OrderHistoryTable";
import JupiterTerminal from "../../components/JupiterTerminal/JupiterTerminal";

const Trading = () => {
  const {
    orders,
    tradeToken,
    setTradeToken,
    tickers,
    publicTrades,
    orderBook,
    apiKey,
    apiSecret,
  } = useApp();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true); // Yükleme durumunu ekliyoruz
  const [selectedOrderType, setSelectedOrderType] = useState("Açık Emirler");
  const [isTradingLeftVisible, setIsTradingLeftVisible] = useState(
    window.innerWidth > 1240
  );
  const [activeTradeMode, setActiveTradeMode] = useState("buy");

  const buyTradeRef = useRef();
  const sellTradeRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsTradingLeftVisible(window.innerWidth > 1240);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleTradingLeft = () => {
    setIsTradingLeftVisible(!isTradingLeftVisible);
  };

  // İlk yüklenme sırasında tradeToken ayarlamaları
  useEffect(() => {
    const initialTradeToken = {
      currencyA: searchParams.get("currencyA"),
      stockA: searchParams.get("stockA"),
      sellPrice: searchParams.get("sellPrice"),
      sellVolume: searchParams.get("sellVolume"),
      amount: searchParams.get("amount"),
      logoURI: searchParams.get("logoURI"),
      contractAddress: searchParams.get("contractAddress"),
      jup: searchParams.get("jup"),
    };

    if (initialTradeToken.currencyA && initialTradeToken.stockA) {
      setTradeToken(initialTradeToken);
      setIsLoading(false); // tradeToken ayarlandığında yükleme durumunu kapat
    } else {
      setIsLoading(true); // tradeToken eksikse yükleme durumunu aç
    }
  }, [location.search, searchParams, setTradeToken]);

  // tradeToken yüklü değilse hata yerine yükleme durumunu gösteriyoruz
  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (
    !apiKey ||
    !apiSecret ||
    !tradeToken ||
    (Array.isArray(tradeToken) && tradeToken.length === 0) ||
    (typeof tradeToken === "object" &&
      !Array.isArray(tradeToken) &&
      Object.keys(tradeToken).length === 0) ||
    (typeof tradeToken === "string" && tradeToken.trim() === "")
  ) {
    return <div>API anahtarı, gizli anahtar veya ticaret tokenı eksik</div>;
  }

  const orderType = [
    { label: "Açık Emirler" },
    { label: "İşlem Geçmişi" },
    { label: "Piyasa" },
  ];

  return (
    <div className="trading">
      <TradingHeader
        toggleLeftPanel={toggleTradingLeft}
        isTradingLeftVisible={isTradingLeftVisible}
        setIsTradingLeftVisible={setIsTradingLeftVisible}
        tickers={tickers}
      />
      <div className="trading__top">
        <div
          className={`trading__left ${
            isTradingLeftVisible ? "mobil__Table-show" : "mobil__Table-hide"
          } `}
        >
          <TradingLeft />
        </div>

        <div className="trading_big_right">
          <div className="trading_big_right_upper_content">
            <div className="trading__middle">
              <div className="d-flex trade-change_buttons">
                <button
                  className="mobile_show buy-button"
                  onClick={() => setActiveTradeMode("buy")}
                >
                  Alış
                </button>
                <button
                  className="mobile_show sell-button"
                  onClick={() => setActiveTradeMode("sell")}
                >
                  Satış
                </button>
              </div>
              <TradingViewWidget />

              <div className="trading__actions">
                <div
                  className={`trade-mode ${
                    activeTradeMode === "buy" ? "active" : ""
                  }`}
                >
                  <TradingTrade ref={buyTradeRef} tradeMode={"buy"} />
                </div>
                <div
                  className={`trade-mode ${
                    activeTradeMode === "sell" ? "active" : ""
                  }`}
                >
                  <TradingTrade ref={sellTradeRef} tradeMode={"sell"} />
                </div>
              </div>
            </div>
            <div className="trading__right">
              <OrderBookTable
                data={orderBook}
                tickers={tickers}
                buyTradeRef={buyTradeRef}
                sellTradeRef={sellTradeRef}
              />
              <div className="jupiter-terminals">
                <JupiterTerminal />
              </div>
            </div>
          </div>
          <TabGroups data={orderType} setLabel={setSelectedOrderType} />
          <div className="trading_history-table">
            {selectedOrderType === "Açık Emirler" && (
              <OpenOrdersTable data={orders} />
            )}
            {selectedOrderType === "İşlem Geçmişi" && <OrderHistoryTable />}
            {selectedOrderType === "Piyasa" && (
              <PublickTradeTable data={publicTrades} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trading;
