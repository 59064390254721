import React, { useState, useEffect } from "react";
import "./KeyRecord.css";
import CustomInput from "../../components/CustomInput/CustomInput";

const KeyRecord = ({ exchange }) => {
  // Input değerleri için state tanımlamaları
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");

  // Component mount olduğunda localStorage'tan verileri çek
  useEffect(() => {
    const storedKey = localStorage.getItem(`${exchange}-Key`);
    const storedSecret = localStorage.getItem(`${exchange}-Secret`);
    if (storedKey) setKey(storedKey);
    if (storedSecret) setSecret(storedSecret);
  }, [exchange]); // Exchange değiştiğinde bu işlemi tekrarla

  // Inputlarda değişiklik olduğunda state'i güncelle
  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  const handleSecretChange = (e) => {
    setSecret(e.target.value);
  };

  // Kaydet butonunun fonksiyonu
  const handleSave = () => {
    localStorage.setItem(`${exchange}-Key`, key);
    localStorage.setItem(`${exchange}-Secret`, secret);
  };

  return (
    <div className="keyrecord-container">
      <div><h3 className="keyrecord-title-exchange">{exchange}</h3></div>
      <CustomInput title={"Key"} value={key} onChange={handleKeyChange} />
      <CustomInput
        title={"Secret"}
        value={secret}
        onChange={handleSecretChange}
      />

      <button
        className="keyrecord-saved-button"
        type="submit"
        onClick={handleSave}
      >
        Kaydet
      </button>
    </div>
  );
};

export default KeyRecord;
