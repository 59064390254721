import React, { useCallback, useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import "./TokenListesi.css";
import { useApp } from "../../context/AppContext";
import TokenListesiTable from "../../components/TokenListesiTable/TokenListesiTable";
import {
  Ekleicon,
  Silicon,
  Listeleicon,
  Gerigetiricon,
  Kaydeticon,
} from "../../assets/icons/icon";
import CreateTokenPopup from "./CreateTokenPopup";
import BackupListPopup from "./BackupListPopup "; // BackupListPopup import edildi
import { getService } from "../../service/ServiceManager";

const JupiterTokenListesi = () => {
  const { tokens, setTokens } = useApp();
  const jupiterService = getService("Jupiter");
  const [searchTerm, setSearchTerm] = useState("");
  const [showBackupList, setShowBackupList] = useState(false);
  const [backups, setBackups] = useState([]);
  const [showCreateTokenPopup, setShowCreateTokenPopup] = useState(false);

  const retrieveTokens = useCallback(() => {
    jupiterService.Token
      .retrieveTokens()
      .then(setTokens)
      .catch((error) => {
        console.error("Token bilgileri alınırken hata oluştu:", error);
      });
  }, [jupiterService, setTokens]); // retrieveTokens fonksiyonunu useCallback ile sabitleyin
  useEffect(() => {
    retrieveTokens();
  }, [jupiterService, setTokens, retrieveTokens]); // Burada retrieveTokens bağımlılık listesine eklendi.
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  
  const refreshTokens = () => {
    jupiterService.Token
      .refreshTokenList()
      .then(
        setTimeout(() => {
          retrieveTokens();
        }, 1000)
      )
      .catch(console.error);
  };

  const clearTokens = async () => {
    if (window.confirm("Tüm kayıtlar silinecek, onaylıyor musunuz?")) {
      jupiterService.Token.clearTokenList().then(setTokens([])).catch(console.error);
    }
  };

  const takeBackup = () => {
    jupiterService.Token
      .backupTokenList()
      .then(
        setTimeout(() => {
          listBackups();
        }, 1000)
      )
      .catch(console.error);
  };

  const listBackups = () => {
    jupiterService.Token.listBackupTables().then(setBackups).catch(console.error);
    setShowBackupList(true);
  };

  const restoreBackup = (backupId) => {
    jupiterService.Token
      .restoreTokenList(backupId)
      .then(
        setTimeout(() => {
          retrieveTokens();
          setShowBackupList(true);
        }, 1000)
      )
      .catch(console.error);
  };

  const deleteBackup = (backupId) => {
    jupiterService.Token
      .deleteBackupTable(backupId)
      .then(
        setTimeout(() => {
          listBackups();
        }, 1000)
      )
      .catch(console.error);
  };

  const filteredTokens = tokens.filter((token) =>
    Object.values(token).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm)
    )
  );

  return (
    <div>
      <ProfileHeader title={"Hesap Bilgileri"} subtitle={"Token Listesi"} />
      <div className="search-and-actions">
        <input
          type="text"
          placeholder="Arama yap..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="tokens-search-input"
        />
        <div className="tokens-info-acitons">
          <span
            title="Kayıt Ekle"
            onClick={() => setShowCreateTokenPopup(true)}
          >
            {Ekleicon}
          </span>
          <span title="Veritabanı Güncelleme" onClick={refreshTokens}>
            {Gerigetiricon}
          </span>
          <span title="Tüm Kayıtları Temizle" onClick={clearTokens}>
            {Silicon}
          </span>
          <span title="Yedek Al" onClick={takeBackup}>
            {Kaydeticon}
          </span>
          <span title="Yedekleri Listele ve Düzenle" onClick={listBackups}>
            {Listeleicon}
          </span>
        </div>
      </div>
      <div className="tokens-info-container">
        <TokenListesiTable
          data={filteredTokens}
          updateToken={jupiterService.Token.modifyToken}
          removeToken={jupiterService.Token.removeToken}
          retrieveTokens={retrieveTokens}
        />

        {showBackupList && (
          <BackupListPopup
            backups={backups}
            onRestore={restoreBackup}
            onDelete={deleteBackup}
            onClose={() => setShowBackupList(false)}
          />
        )}
        {showCreateTokenPopup && (
          <CreateTokenPopup
            onClose={() => setShowCreateTokenPopup(false)}
            onSubmit={jupiterService.Token.createToken}
            retrieveTokens={retrieveTokens}
          />
        )}
      </div>
    </div>
  );
};

export default JupiterTokenListesi;
