import React from "react";
import "./BackupListPopup.css";
const BackupListPopup = ({ backups, onRestore, onDelete, onClose }) => {
  return (
    <div className="backup-list-popup">
      <div className="popup-overlay" onClick={onClose}></div>
      <div className="popup-content">
        <h2>Yedek Listesi</h2>
        {backups.length > 0 ? (
          backups.map((backup) => (
            <div key={backup.tablename} className="backup-item">
              <span>{backup.tablename}</span>
              <button type="submit" onClick={() => onRestore(backup.tablename)}>
                Restore Et
              </button>
              <button type="button" onClick={() => onDelete(backup.tablename)}>Sil</button>
            </div>
          ))
        ) : (
          <p>Yedek bulunamadı.</p>
        )}
        <div className="backup-item">
          <button type="button" onClick={onClose}>Kapat</button>
        </div>
      </div>
    </div>
  );
};

export default BackupListPopup;
