import React, { useEffect } from "react";
import defaultStyles from "./OpenOrdersTable.module.css";
import { useApp } from "../../context/AppContext";

const OpenOrdersTable = ({
  data,
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
}) => {
  const { service, apiKey, apiSecret ,tradeToken,orders,setOrders} = useApp();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString("tr-TR");
    const timePart = date.toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${datePart} \n ${timePart}`;
  };

  useEffect(() => {
    service.Spot.openOrders(tradeToken.currencyA + "_USDT", {
      key: apiKey,
      secret: apiSecret,
    })
      .then(setOrders)
      .catch((error) => {
        console.error("Token bilgileri alınırken hata oluştu:", error);
      });


  }, [service,apiKey,apiSecret,tradeToken.currencyA,setOrders]); // Yalnızca service değiştiğinde çalışır

  const cancelOrder = async (id, currency_pair) => {
    try {
      const cancel = await service.Spot.cancelOrder(id, currency_pair, {
        key: apiKey,
        secret: apiSecret,
      });
      console.log("Order created:", cancel);
    } catch (error) {
      console.error("Order creation error:", error);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        
      </div>
      <div className={styles.tbody}>
      <div className={styles.tr}>
          <span className={styles.th}>Zaman</span>
          <span className={styles.th}>Piyasa</span>
          <span className={styles.th}>Fiyat</span>
          <span className={styles.th}>Tamamlandı / Miktar</span>
          <span className={styles.th}>Tamamlandı / Tutar</span>
          <span className={styles.th} onClick={() => cancelOrder("", tradeToken.currencyA+"_USDT")}>Tümü İptal</span>
        </div>
        {orders.length > 0 ? (
          orders.map((row, rowIndex) => (
            <div className={styles.tr} key={rowIndex}>
              <span className={styles.td}>{formatDate(row.createTime)}</span>
              <span className={styles.td}>{row.currency_pair}</span>
              <span className={`${styles.td} ${row.side === 'sell' ? styles.negatif : styles.pozitif}`} title={row.price}>
                {row.price}
              </span>
              <span className={styles.td}>
                {row.amount} / {parseFloat(row.left).toFixed(4)} ({((row.left * 100) / row.amount ).toFixed(2)}%)
              </span>
              <span className={styles.td}>
                {(row.price * row.amount).toFixed(4)} / {parseFloat(row.total).toFixed(4)} 
              </span>
              <span
                className={styles.td}
                onClick={() => cancelOrder(row.id, row.currency_pair)}
              >
                İptal
              </span>
            </div>
          ))
        ) : (
          <div className={styles.tr}>
            <span className={styles.td}>Açık emir yok</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenOrdersTable;
