import React, { useEffect, useState } from "react";
import "./Select.css";  // Burada Select componentine ait CSS dosyasını dahil ettik.
import { Downicon, Lefticon } from "../../assets/icons/icon";

const Select = ({ data, onTokenSelect, disabledToken, selectedToken }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Seçili tokeni parent komponente bildir.
    onTokenSelect(selectedToken);
  }, [selectedToken, onTokenSelect]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectToken = (token) => {
    onTokenSelect(token);
    setIsOpen(false);  // Dropdown'ı kapat.
    setSearchTerm(""); // Arama terimini temizle.
  };

  const filterTokens = (tokens) => {
    return tokens.filter(token =>
      (token.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       token.symbol.toLowerCase().includes(searchTerm.toLowerCase())) &&
       token.symbol !== disabledToken?.symbol
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderRows = () => {
    const filteredData = filterTokens(data);
    if (filteredData.length === 0) {
      return <div className="select-no-data">No valid data available.</div>;
    }
    return filteredData.map((token, index) => (
      <div key={index} className="select-row" onClick={() => handleSelectToken(token)}>
        <img className="select-icon" src={token.src} alt={token.symbol} />
        <div className="select-info">
          <span>{token.symbol}</span>
          <span>{token.name}</span>
        </div>
        <div className="select-label">
          <span>{token.amount}</span>
          <span>{token.value}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className="select-container">
      <div className="select-item" onClick={toggleDropdown}>
        {selectedToken ? (
          <>
            <img className="select-icon" src={selectedToken.src} alt="ICON" />
            <span className="select-symbol">{selectedToken.symbol}</span>
          </>
        ) : "No data available"}
        {Downicon}
      </div>
      {isOpen && (
        <div className="select-row-container">
          <div className="select-info-head">
            <span onClick={() => setIsOpen(false)}>{Lefticon}</span>
            <h2>Select Token</h2>
          </div>
          <div className="select-search">
            <input 
              type="text" 
              value={searchTerm} 
              onChange={handleSearchChange} 
              placeholder="Arama"
              required 
            />
          </div>
          {renderRows()}
        </div>
      )}
    </div>
  );
};

export default Select;
