import React from "react";
import defaultStyles from "./BalanceTable.module.css";
import TransferButton from "../../pages/Wallet/TransferButton";

const BalanceTable = ({
  data,
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
  toggleComponent,
  setWalletToken
}) => {



  return (

    <div className={styles.table}>
      <div className={styles.thead}>
        <div className={styles.tr}>
          <span className={styles.th}>Coin</span>
          <span className={styles.th}>Kullanılabilir</span>
          <span className={styles.th}>Kilitli</span>
          <span className={styles.th}>Transfer</span>
        </div>
      </div>
      <div className={styles.tbody}>
        {data.length > 0
          ? data.map((row, rowIndex) => (
              <div
                className={styles.tr}
                key={rowIndex}
                
              >
                <span className={styles.td}>
                  {row.currency}
                </span>
                <span className={styles.td}>
                 {parseFloat(row.available).toFixed(2)}
                </span>
                <span className={styles.td}>
                 {parseFloat(row.locked).toFixed(2)}
                </span>
                <span className={styles.td}>
                <TransferButton  currency={row.currency}/>
                </span>
               
              </div>
            ))
          : noRowsContent}
      </div>
    </div>
  );
};

export default BalanceTable;
