import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Saaticon,
  Settingicon,
  Downicon,
  Backicon,
} from "../../assets/icons/icon";
import { useApp } from "../../context/AppContext";
import JupiterPriceSettingPopup from "../../popup/JupiterPriceSettingPopup";
import AlarmPopup from "../../popup/AlarmPopup"; // AlarmPopup bileşenini ekleyin
import "./TradingHeader.css";

const TradingHeader = ({ toggleLeftPanel, tickers }) => {
  const { tradeToken } = useApp();
  let navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Jupiter popup açık/kapalı durumu için state
  const [isAlarmPopupOpen, setIsAlarmPopupOpen] = useState(false); // Alarm popup açık/kapalı durumu için state
  const logoURI = tradeToken.logoURI || "unknown.svg";

  return (
    <div className="trading__header">
      <div className="trading__header_left">
        <div>
          <span onClick={() => navigate(`/`)} className="trading__header_back">
            {Backicon}
          </span>
        </div>
        <div className="trading__header_info">
          <img src={logoURI} alt="icon" />
          <h5>{tradeToken.currencyA}</h5>
          <span>{tradeToken.currencyA}-USDT </span>
          <span className="trading__togle_menu" onClick={toggleLeftPanel}>
            {Downicon}
          </span>
        </div>
        <div className="trading__column mobile_hidden">
          <span>Son Fiyat</span>
          <span>{tickers.p}</span>
        </div>
        <div className="trading__column mobile_hidden">
          <span>% Değişim</span>
          <span
            className={`${parseFloat(tickers.r) > 0 ? "pozitif" : "negatif"}`}
          >
            {tickers.r}
          </span>
        </div>
      </div>
      <div className="trading__header_right">
        <span onClick={() => setIsAlarmPopupOpen(true)}>{Saaticon}</span>
        <span onClick={() => setIsPopupOpen(true)}>{Settingicon}</span>
      </div>

      {isPopupOpen && (
        <div className="popup-overlay" onClick={() => setIsPopupOpen(false)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <JupiterPriceSettingPopup />
          </div>
        </div>
      )}
      {isAlarmPopupOpen && (
        <div
          className="popup-overlay"
          onClick={() => setIsAlarmPopupOpen(false)}
        >
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <AlarmPopup setIsAlarmPopupOpen={setIsAlarmPopupOpen} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TradingHeader;
