import React, { useState, useEffect } from "react";
import {
  Checkicon,
  Downokicon,
  Upperokicon,
  Greenroundicon,
  Grayroundicon,
  RightArrowicon
} from "../../assets/icons/icon";
import "./Profile.css";
import UserInfo from "./UserInfo";
import TokenListesi from "./TokenListesi";
import { useApp } from "../../context/AppContext";
import JupiterTokenListesi from "./JupiterTokenListesi";

const Profile = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("userInfo");
  const { selectedExchange } = useApp();
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleSectionChange = (section) => setActiveSection(section);
  // State for storing the email from localStorage
  const [email, setEmail] = useState("Anonim"); // Default to 'Anonim'

  useEffect(() => {
    // Fetch the email from localStorage
    const storedEmail = localStorage.getItem("email");
    // Update the state if email exists and is not empty
    if (storedEmail && storedEmail.trim()) {
      setEmail(storedEmail);
    }
  }, []);

  const logOut = ()=>{
    localStorage.setItem("token", "");
    localStorage.setItem("email", "");
    window.location.reload()
  }
  return (
    <div className="profile">
      <div className="profile__sidebar">
        <div className="profile__header">
          <img src="/logo192.png" alt="Logo" className="profile__logo" />
          <div>{email}</div>
          {Checkicon}
        </div>
        <div className="profile__dropdown">
          <button className="profile__dropdown-toggle" onClick={toggleDropdown}>
            Hesap Bilgileri
            {!isDropdownOpen ? Upperokicon : Downokicon}
          </button>
          {isDropdownOpen && (
            <ul className="profile__dropdown-menu">
              <li onClick={() => { handleSectionChange("userInfo"); toggleDropdown(); }}>
                {activeSection === "userInfo" ? Greenroundicon : Grayroundicon}{" "}
                Kullanıcı Bilgileri
              </li>
              <li onClick={() => {  handleSectionChange("TokenListesi"); toggleDropdown(); }}>
                {activeSection === "TokenListesi"
                  ? Greenroundicon
                  : Grayroundicon}{" "}
                {selectedExchange} Token Listesi
              </li>
              <li onClick={() => {  handleSectionChange("JupiterTokenListesi"); toggleDropdown(); }}>
                {activeSection === "JupiterTokenListesi"
                  ? Greenroundicon
                  : Grayroundicon}{" "}
                Jupiter Listesi
              </li>
              <li onClick={() => logOut}>
              
              {RightArrowicon}  Çıkış
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="profile__main">
        {activeSection === "userInfo" && <UserInfo />}
        {activeSection === "TokenListesi" && <TokenListesi />}
        {activeSection === "JupiterTokenListesi" && <JupiterTokenListesi />}
      </div>
    </div>
  );
};

export default Profile;
