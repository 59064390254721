import React from 'react';
import defaultStyles from './StepIndicator.module.css';

const StepIndicator = ({ data = [{ text: "Step1" }], activeIndex, externalStyles }) => {
    const styles = externalStyles || defaultStyles;

    // Use a derived state for data to ensure immutability of props
    const steps = data.length === 1 ? [...data, { text: "Step2" }] : data;

    // Function to return the appropriate style based on the step's index and active index
    const getStepStyle = (index, layer) => {
        const baseStyle = `${styles.progressCircle} ${styles[`progressLayer${layer}`]}`;
        return `${baseStyle} ${index <= activeIndex ? styles.stepTextActive : getInactiveStyle(layer)}`;
    };

    const getInactiveStyle = (layer) => {
        switch (layer) {
            case 'Primary':
                return styles.progressGray;
            case 'Secondary':
                return styles.progressDarkGray;
            case 'Tertiary':
                return styles.progressBlack;
            default:
                return '';
        }
    };

    return (
        <div className={styles.stepIndicatorMainContainer}>
            <div className={styles.stepProgressWrapper}>
                {steps.map((step, index) => (
                    <React.Fragment key={step.text}>
                        <div className={styles.stepIconBg}>
                            <div className={getStepStyle(index, 'Primary')}></div>
                            <div className={getStepStyle(index, 'Secondary')}></div>
                            <div className={getStepStyle(index, 'Tertiary')}></div>
                        </div>
                        {index < steps.length - 1 && <div className={styles.stepDottedLine}></div>}
                    </React.Fragment>
                ))}
            </div>
            <div className={styles.stepDescriptionWrapper}>
                {steps.map((step, index) => (
                    <div key={step.text} className={index <= activeIndex ? styles.stepTextActive : ''}>{step.text}</div>
                ))}
            </div>
        </div>
    );
}

export default React.memo(StepIndicator);
