import React from "react";
import defaultStyles from "./PublickTradeTable.module.css";

const PublickTradeTable = ({
  data,
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString("tr-TR");
    const timePart = date.toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${datePart} \n ${timePart}`;
  };
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <div className={styles.tr}>
          <span className={styles.th}>Zaman</span>
          <span className={styles.th}>Fiyat(USDT)</span>
          <span className={styles.th}>Toplam(USDT)</span>
        </div>
      </div>
      <div className={styles.tbody}>
        {data && data.length > 0
          ? data.map((row, rowIndex) => (
              <div className={styles.tr} key={rowIndex}>
                <span className={styles.td}>{formatDate(row.t)}</span>
                <span className={`${styles.td} ${ row.s ==="buy" ? styles.pozitif : styles.negatif}`}>{row.p}</span>
           
                <span className={styles.td}>{(row.p * row.v).toFixed(4)}</span>
              </div>
            ))
          : (
            <div className={styles.tr} >
              <span className={styles.td}>Alış Satış Yok</span>
            </div>
          )}
      </div>
    </div>
  );
};

export default PublickTradeTable;
