import React, { useEffect, useState } from "react";
import "./Deposit.css";
import StepIndicator from "../../layout/StepIndicator/StepIndicator";
import { Backicon } from "../../assets/icons/icon";
import DepositHistoryTable from "../../components/DepositHistoryTable/DepositHistoryTable";
import { useApp } from "../../context/AppContext";
import SelectOptions from "../../components/SelectOptions/SelectOptions";
import { useNavigate, useParams } from "react-router-dom";

const Deposit = () => {
  const data = [{ text: "Ağ Seçimi" }, { text: "Cüzdan Adresi" }];
  const {
    service,
    depositHistory,
    apiKey,
    apiSecret,
  } = useApp();
  const [chain, setChain] = useState([]);
  const [selectedChain, setSelectedChain] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const { currency } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    service.Wallet.depositAddress(currency, {
      key: apiKey,
      secret: apiSecret,
    })
      .then((response) => {
        setChain(response);
        setSelectedChain(response[0].chain);
        setDepositAddress(response[0].address);
      })
      .catch((error) => {
        console.error("Token bilgileri alınırken hata oluştu:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const handleChainChange = (event) => {
    const selected = chain.find((ch) => ch.chain === event.target.value);
    if (selected) {
      setSelectedChain(selected.chain);
      setDepositAddress(selected.address);
    }
  };

  return (
    <div className="deposit-container">
      <div className="deposit-top">
        <div className="deposit-left">
          <div className="deposit-left-top">
            <span className="wallet-back-btn" onClick={() =>  navigate(`/wallet`)}>
              {Backicon}
            </span>
            <span>{currency}</span>
          </div>
      <h2>Yatırma</h2>

          <div className="wallet-selectDiv">
            <label>Ağ</label>

            <SelectOptions
              value={selectedChain}
              options={chain.map((ch) => ({
                value: ch.chain,
                label: ch.chain,
              }))}
              handleChange={handleChainChange}
              required={true}
            />
          
           
          </div>
          <div className="wallet-inputDiv">
            <label>Cüzdan Adresi</label>
            <input value={depositAddress} readOnly />
          </div>
        </div>
        <div className="deposit-right">
          <StepIndicator data={data} activeIndex={0} />
        </div>
      </div>
      <div className="deposit-bottom">
        <DepositHistoryTable data={depositHistory} />
      </div>
    </div>
  );
};

export default Deposit;
