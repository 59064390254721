import React from "react";
import "./CustomInput.css";

const CustomInput = ({ title, value, onChange, required }) => {
  return (
    <div className="custom-input">
      <label htmlFor={title} className="custom-input-label">{title}</label>
      <input type="text" id={title} name={title} value={value} onChange={onChange} required={required} />
    </div>
  );
};

export default CustomInput;
