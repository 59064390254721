import axios from "axios";

const API_URL = "https://gate.gangue.io/";
const axiosInstance = axios.create({ baseURL: API_URL });

export const Token = {
  retrieveTokens: async (params) => {
    try {
      const response = await axiosInstance.get("tokens?chainName=SOLANA", {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  onChain: async (currency, params) => {
    try {
      const response = await axiosInstance.get(`tokens?currency=${currency}`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },

  createToken: async (params) => {
    try {
      const response = await axiosInstance.post("tokens", params);
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  modifyToken: async (updatedRow) => {
    try {
      const { currency, chainName, ...rest } = updatedRow;
      let params = `currency=${currency}&chainName=${chainName}`;
      Object.keys(rest).forEach((key) => {
        params += `&new${key}=${rest[key]}`;
      });
      const response = await axiosInstance.put(`tokens?${params}`);
      console.log("Updated row:", updatedRow);
      return response.data;
    } catch (error) {
      console.error("Token güncellenirken hata oluştu:", error.data);
      throw error;
    }
  },
  removeToken: async (updatedRow) => {
    try {
      const { currency, chainName, ...rest } = updatedRow;
      let params = `currency=${currency}&chainName=${chainName}`;
      Object.keys(rest).forEach((key) => {
        params += `&new${key}=${rest[key]}`;
      });
      const response = await axiosInstance.delete(`tokens?${params}`);
      console.log("Updated row:", response.data.deletedToken);
      return response.data;
    } catch (error) {
      console.error("Token güncellenirken hata oluştu:", error.data);
      throw error;
    }
  },

  refreshTokenList: async () => {
    try {
      const response = await axiosInstance.post("database/refresh");
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  clearTokenList: async () => {
    try {
      const response = await axiosInstance.delete("database/clear");
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  backupTokenList: async () => {
    try {
      const response = await axiosInstance.post("database/backups/create");
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  restoreTokenList: async (table) => {
    try {
      const response = await axiosInstance.put(
        `database/backups/restore/${table}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  listBackupTables: async () => {
    try {
      const response = await axiosInstance.get("database/backups");
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
  deleteBackupTable: async (table) => {
    try {
      const response = await axiosInstance.delete(`database/backups/${table}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching tokens:", error.data);
      throw error;
    }
  },
};
