import axios from "axios";

// API URL tanımı
const API_URL = "https://mexc.gangue.io/";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API isteklerini yapacak temel fonksiyon
const sendRequest = async (
  method,
  path,
  data = {},
  params = {},
  headers = {}
) => {
  try {
    const response = await axiosInstance({
      method,
      url: path,
      params,
      data,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error);
    throw error;
  }
};

export const Spot = {
  // Sipariş oluşturma
  // type: 'LIMIT' veya 'MARKET'
  // currency_pair: 'BTC_USDT' gibi döviz çifti
  // side: 'BUY' veya 'SELL'
  // amount: Miktar
  // total: Toplam tutar (type 'MARKET' ve side 'BUY' için gerekli)
  // price: Fiyat (type 'LIMIT' için gerekli)
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.createOrder('LIMIT', 'BTC_USDT', 'BUY', 1, null, 10000, { key: 'api-key', secret: 'api-secret' })
  createOrder: async (type, currency_pair, side, amount, total,price, headers) => {
    // Local storage'dan Mexc-APIV2-Cookie ve Mexc-APIV2-Mh değerlerini al
    const mexcAPIV2Cookie = localStorage.getItem("Mexc-APIV2-Cookie");
    const mexcAPIV2Mh = localStorage.getItem("Mexc-APIV2-Mh");
    const defaultSymbolV2 = JSON.parse(
      localStorage.getItem("defaultSYMBOLV2Cache") || "[]"
    );

    // Eğer cookie ve mh boşsa orijinal createOrder'dan devam et
    if (!mexcAPIV2Cookie || !mexcAPIV2Mh) {
      const orderData = {
        type,
        currency_pair,
        side,
        amount,
        total,
        price
      };
      return await sendRequest('POST', '/spot/createOrder', orderData, {}, headers);
    }

    // Currency bilgisi (_ öncesindeki kısım)
    const [currency] = currency_pair.split("_");

    // defaultSymbolV2 içerisinde currency'ye göre arama yap
    const symbolData = defaultSymbolV2.find(
      (item) => item.currency === currency
    );

    // V2 için header bilgilerini güncelle
    const updatedHeaders = {
      ...headers,
      mh: mexcAPIV2Mh,
      mxcookie: mexcAPIV2Cookie,
    };

    // V2 için body verisi
    const orderDataV2 = {
      currencyId: symbolData.currencyId,
      marketCurrencyId: symbolData.marketCurrencyId,
      type: side,
      price: price.toString(),
      quantity: amount,
    };

    try {
      const response = await sendRequest(
        "POST",
        "https://mexc.gangue.io/spot/createOrderV2",
        orderDataV2,
        {},
        updatedHeaders
      );

      return response;
    } catch (error) {
     
      // Eğer response bir nesne ve 401 Unauthorized hatası içeriyorsa cookie ve mh değerlerini sil
      if (error && error.response.data.code === 401) {
        console.log("Unauthorized: Cookie  siliniyor.");
        localStorage.removeItem("Mexc-APIV2-Cookie");
      }
    }
  },

  // Siparişi iptal etme
  // id: Sipariş ID'si
  // currency_pair: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.cancelOrder('12345', 'BTC_USDT', { key: 'api-key', secret: 'api-secret' })
  cancelOrder: async (id, currency_pair, headers) => {
    const params = {
      currency_pair,
      id,
    };
    return await sendRequest(
      "DELETE",
      "/spot/cancelOrder",
      {},
      params,
      headers
    );
  },

  // Açık siparişleri listeleme
  // currency_pair: Opsiyonel, 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.openOrders('BTC_USDT', { key: 'api-key', secret: 'api-secret' })
  openOrders: async (currency_pair, headers) => {
    const params = { currency_pair };
    return await sendRequest("GET", "/spot/openOrders", {}, params, headers);
  },

  // Belirli bir siparişi getirme
  // orderId: Sipariş ID'si
  // currency: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.getOrder('12345', 'BTC_USDT', { key: 'api-key', secret: 'api-secret' })
  getOrder: async (orderId, currency, headers) => {
    const params = { currency, orderId };
    return await sendRequest("GET", "/spot/getOrder", {}, params, headers);
  },

  // Ticker bilgilerini getirme
  // currency_pair: Opsiyonel, 'BTC_USDT' gibi döviz çifti
  // Örnek Kullanım: Spot.getTickers('BTC_USDT')
  getTickers: async (currency_pair) => {
    const params = currency_pair ? { currency_pair } : {};
    return await sendRequest("GET", "/spot/getTickers", {}, params);
  },

  // Sipariş geçmişini listeleme
  // currency_pair: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.orderHistory('BTC_USDT', { key: 'api-key', secret: 'api-secret' })
  orderHistory: async (currency_pair, headers) => {
    const params = { currency_pair };
    return await sendRequest("GET", "/spot/orderHistory", {}, params, headers);
  },

  // Varsayılan sembolleri getirme
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.defaultSYMBOL({ key: 'api-key', secret: 'api-secret' })
  defaultSYMBOL: async (headers) => {
    const cacheKey1 = "defaultSYMBOLCache";
    const cacheTimestampKey1 = "defaultSYMBOLTimestamp";
    const cacheKey2 = "defaultSYMBOLV2Cache";
    const cacheTimestampKey2 = "defaultSYMBOLV2Timestamp";
    const eightThousandHours = 8000 * 60 * 60 * 1000; // 8000 saat (milisaniye cinsinden)
    const eightHours = 8 * 60 * 60 * 1000; // 8 saat (milisaniye cinsinden)

    // Local storage'dan zaman damgasını ve veriyi al
    const cachedResponse1 = JSON.parse(localStorage.getItem(cacheKey1));
    const cachedTimestamp1 = localStorage.getItem(cacheTimestampKey1);

    const cachedResponse2 = JSON.parse(localStorage.getItem(cacheKey2));
    const cachedTimestamp2 = localStorage.getItem(cacheTimestampKey2);

    let result1, result2;

    // Eğer defaultSYMBOL cache 8000 saatten daha eskiyse veya cache yoksa yeni istek yap
    if (
      !cachedResponse1 ||
      !cachedTimestamp1 ||
      Date.now() - cachedTimestamp1 > eightThousandHours
    ) {
      result1 = await sendRequest(
        "GET",
        "/spot/defaultSYMBOL",
        {},
        {},
        headers
      );

      // Yanıtı ve zaman damgasını local storage'a kaydet
      localStorage.setItem(cacheKey1, JSON.stringify(result1));
      localStorage.setItem(cacheTimestampKey1, Date.now().toString());
    } else {
      result1 = cachedResponse1;
    }

    // Eğer defaultSYMBOLV2 cache 8 saatten daha eskiyse veya cache yoksa yeni istek yap
    if (
      !cachedResponse2 ||
      !cachedTimestamp2 ||
      Date.now() - cachedTimestamp2 > eightHours
    ) {
      result2 = await sendRequest(
        "GET",
        "/spot/defaultSYMBOLV2",
        {},
        {},
        headers
      );

      // Yanıtı ve zaman damgasını local storage'a kaydet
      localStorage.setItem(cacheKey2, JSON.stringify(result2));
      localStorage.setItem(cacheTimestampKey2, Date.now().toString());
    } else {
      result2 = cachedResponse2;
    }

    return { defaultSYMBOL: result1, defaultSYMBOLV2: result2 };
  },
};
