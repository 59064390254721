import { useEffect, useState } from "react";
import { getService } from "../service/ServiceManager";

export const useListenKey = () => {
  const [listenKey, setListenKey] = useState(null);

  useEffect(() => {
    let keepAliveInterval;

    const fetchListenKey = async () => {
      const apiKey = localStorage.getItem("Mexc-Key");
      const apiSecret = localStorage.getItem("Mexc-Secret");

      if (!apiKey || !apiSecret) {
        console.log("API Key or Secret is not available in localStorage.");
        return;
      }

      try {
        const exchangeName = "Mexc"; // Exchange adı sabit olarak belirlendi
        const service = getService(exchangeName).Wallet;
        let keysData = await service.getListenKey({
          key: apiKey,
          secret: apiSecret,
        });
        let key = keysData.listenKey.length > 0 ? keysData.listenKey[0] : null;

        if (!key) {
          const newKeyData = await service.createListenKey({
            key: apiKey,
            secret: apiSecret,
          });
          key = newKeyData.listenKey;
        }

        setListenKey(key);

        // Listen key'i aktif tutma
        const KEEP_ALIVE_INTERVAL = 15 * 60 * 1000; // 30 minutes
        keepAliveInterval = setInterval(() => {
          service.keepaliveListenKey(key, { key: apiKey, secret: apiSecret });
        }, KEEP_ALIVE_INTERVAL);
      } catch (error) {
        console.error("Error fetching or creating listen key:", error);
      }
    };

    fetchListenKey();

    return () => {
      clearInterval(keepAliveInterval);
    };
  }, []);

  return listenKey;
};
