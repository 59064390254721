import React, { useEffect, useState } from "react";
import "./Piyasalar.css";
import TabGroups from "../../components/TabGroups/TabGroups";
import ArbitrageTable from "../../components/ArbitrageTable/ArbitrageTable";
import { useApp } from "../../context/AppContext";
import RadioButton from "../../components/RadioButton/RadioButton";
import { Saaticon, Settingicon } from "../../assets/icons/icon";
import JupiterPriceSettingPopup from "../../popup/JupiterPriceSettingPopup";
import AlarmPopup from "../../popup/AlarmPopup"; // AlarmPopup bileşenini ekleyin
import JupiterTerminal from "../../components/JupiterTerminal/JupiterTerminal";

const Piyasalar = () => {
  const {
    exchangeGroups,
    arbitrageTable,
    filterGroups,
    setFilterGroups,
    selectedOption,
    setSelectedOption,
  } = useApp();

  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi için yeni state
  const [isJupiterPopupOpen, setIsJupiterPopupOpen] = useState(false); // Jupiter popup açık/kapalı durumu için state
  const [isAlarmPopupOpen, setIsAlarmPopupOpen] = useState(false); // Alarm popup açık/kapalı durumu için state

  // Filtreleme işlevi
  useEffect(() => {
    let data = arbitrageTable;

    const normalizedFilterGroups = filterGroups.toLowerCase();

    // FilterGroups ile borsa filtresi uygulanıyor
    if (filterGroups !== "Hepsi") {
      data = data.filter(
        (item) =>
          item.stockA.toLowerCase().includes(normalizedFilterGroups) ||
          item.stockB.toLowerCase().includes(normalizedFilterGroups)
      );
    }

    // SelectedOption ile işlem türü filtresi uygulanıyor
    if (selectedOption === "Alış") {
      data = data.filter((item) =>
        item.stockA.toLowerCase().includes(normalizedFilterGroups)
      );
    } else if (selectedOption === "Satış") {
      data = data.filter((item) =>
        item.stockB.toLowerCase().includes(normalizedFilterGroups)
      );
    }

    // Arama işlevi ekleniyor
    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase();
      data = data.filter(
        (item) =>
          item.currencyA.toLowerCase().includes(normalizedSearchTerm) ||
          item.currencyB.toLowerCase().includes(normalizedSearchTerm)
      );
    }

    setFilteredData(data);
  }, [filterGroups, selectedOption, arbitrageTable, searchTerm]);

  return (
    <div className="container__piyasalar">
      <div className="piyasalar-left">
        <div className="piyasalar-left-a">
          <h1 className="mobile-hidden">Piyasalar</h1>
          <div className="container__head">
            <div className="piyasalar-filter">
              <input
                type="text"
                placeholder="Arama yap..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <RadioButton
                label="Hepsi"
                name="filterOption"
                checked={selectedOption === "Hepsi"}
                onChange={() => setSelectedOption("Hepsi")}
              />
              <RadioButton
                label="Alış"
                name="filterOption"
                checked={selectedOption === "Alış"}
                onChange={() => setSelectedOption("Alış")}
              />
              <RadioButton
                label="Satış"
                name="filterOption"
                checked={selectedOption === "Satış"}
                onChange={() => setSelectedOption("Satış")}
              />
            </div>
            <div className="time-icon">
              <span onClick={() => setIsAlarmPopupOpen(true)}>{Saaticon}</span>
              <span onClick={() => setIsJupiterPopupOpen(true)}>
                {Settingicon}
              </span>
            </div>
          </div>
          <TabGroups
            data={exchangeGroups}
            setLabel={setFilterGroups}
            Index={exchangeGroups.findIndex(
              (item) => item.label === filterGroups
            )}
          />

          <ArbitrageTable data={filteredData} />
        </div>
        <div className="piyasalar-left-b">
        <JupiterTerminal />
        </div>
        
      </div>

      {isJupiterPopupOpen && (
        <div
          className="popup-overlay"
          onClick={() => setIsJupiterPopupOpen(false)}
        >
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <JupiterPriceSettingPopup />
          </div>
        </div>
      )}
      {isAlarmPopupOpen && (
        <div
          className="popup-overlay"
          onClick={() => setIsAlarmPopupOpen(false)}
        >
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <AlarmPopup setIsAlarmPopupOpen={setIsAlarmPopupOpen} />{" "}
            {/* Popup kapatma işlevi ekleyin */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Piyasalar;
