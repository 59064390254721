import React, { useState, useEffect } from 'react';
import { useApp } from '../context/AppContext';

const JupiterPriceSettingPopup = () => {
  const { setJupiterSend } = useApp();
  const [maxAmount, setMaxAmount] = useState(() => {
    return localStorage.getItem('maxAmount') || 1500;
  });
  const [maxPct, setMaxPct] = useState(() => {
    return localStorage.getItem('maxPct') || 2.5;
  });

  useEffect(() => {
    localStorage.setItem('maxAmount', maxAmount);
  }, [maxAmount]);

  useEffect(() => {
    localStorage.setItem('maxPct', maxPct);
  }, [maxPct]);

  const handleMaxAmountChange = (event) => {
    setMaxAmount(event.target.value);
  };

  const handleMaxPctChange = (event) => {
    setMaxPct(event.target.value);
  };

  const handleApply = () => {
    setJupiterSend({
      method: 'updateParams',
      params: {
        bestPrice: true,
        maxAmount: maxAmount,
        maxPct: maxPct,
      },
    });
    alert('Değerler kaydedildi!');
  };

  return (
    <div style={popupStyle}>
      <h2>Ayarları Düzenle</h2>
      <div style={inputGroupStyle}>
        <label>Max Amount:</label>
        <input
          type="number"
          value={maxAmount}
          onChange={handleMaxAmountChange}
          style={inputStyle}
        />
      </div>
      <div style={inputGroupStyle}>
        <label>Max Pct:</label>
        <input
          type="number"
          value={maxPct}
          onChange={handleMaxPctChange}
          style={inputStyle}
        />
      </div>
      <button onClick={handleApply} style={buttonStyle}>Uygula</button>
    </div>
  );
};

const popupStyle = {
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '20px',
  width: '300px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

const inputGroupStyle = {
  marginBottom: '10px',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  boxSizing: 'border-box',
  marginTop: '5px',
};

const buttonStyle = {
  backgroundColor: '#007BFF',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default JupiterPriceSettingPopup;
