import React from "react";
import defaultStyles from "./DepositHistoryTable.module.css";

const DepositHistoryTable = ({
  data,
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,

}) => {
  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString('tr-TR');
    const timePart = date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
    return `${datePart} \n ${timePart}`;
  };
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
      </div>
      <div className={styles.tbody}>
      <div className={styles.tr}>
          <span className={styles.th}>Tarih</span>
          <span className={styles.th}>Coin</span>
          <span className={styles.th}>Ağ</span>
          <span className={styles.th}>Adres</span>
          <span className={styles.th}>Tx ID</span>
     
          <span className={styles.th}>Miktar</span>
          <span className={styles.th}>Durum</span>
        </div>
        {data.length > 0
          ? data.map((row, rowIndex) => (
              <div className={styles.tr} key={rowIndex}>
                <span className={styles.td}>{formatDate(row.time)}</span>
                <span className={styles.td}>{row.currency}</span>
                <span className={styles.td}>{row.chain}</span>
                <span className={styles.td} title={row.address}>{formatAddress(row.address)}</span>
                <span className={styles.td} title={row.txid}>{formatAddress(row.txid)}</span>
   
                <span className={styles.td}>{row.amount}</span>
                <span className={styles.td}>{row.status}</span>
               
              </div>
            ))
          : noRowsContent}
      </div>
    </div>
  );
};

export default DepositHistoryTable;
