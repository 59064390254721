const BASE_URL = 'https://auth.gangue.io'; // Backend API'nizin temel URL'si

const EmailAuthService = {
  // Kullanıcının e-mail adresini backend'e gönderme
  sendEmail: async (mail) => {
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mail }),
      });
      if (!response.ok) {
        throw new Error('E-mail gönderilirken bir hata oluştu.');
      }
      return await response.json(); // Backend'ten gelen yanıtı döndür
    } catch (error) {
      console.error("sendEmail Error:", error);
      throw error;
    }
  },

  // Kullanıcının girdiği OTP'yi ve e-mail adresini backend'e göndererek kontrol ettirme
  verifyOTP: async (mail, otpCode) => {
    try {
      const response = await fetch(`${BASE_URL}/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mail, otp: otpCode }),
      });
      if (!response.ok) {
        throw new Error('OTP doğrulanırken bir hata oluştu.');
      }
      return await response.json(); // Backend'ten gelen yanıtı döndür (token vb.)
    } catch (error) {
      console.error("verifyOTP Error:", error);
      throw error;
    }
  },
};

export default EmailAuthService;
