import React, { useState, useEffect } from "react";
import "./KeyRecord.css";
import CustomInput from "../../components/CustomInput/CustomInput";

const MexcMarketV2 = ({ exchange }) => {
  // Input değerleri için state tanımlamaları
  const [cookie, setCookie] = useState("");
  const [mh, setMh] = useState("");

  // Component mount olduğunda localStorage'tan verileri çek
  useEffect(() => {
    const storedCookie = localStorage.getItem(`${exchange}-Cookie`);
    const storedMh = localStorage.getItem(`${exchange}-Mh`);

    if (storedCookie) setCookie(storedCookie);
    if (storedMh) setMh(storedMh);
  }, [exchange]); // Exchange değiştiğinde bu işlemi tekrarla

  // Inputlarda değişiklik olduğunda state'i güncelle
  const handleCookieChange = (e) => {
    setCookie(e.target.value);
  };

  const handleMhChange = (e) => {
    setMh(e.target.value);
  };

  // Kaydet butonunun fonksiyonu
  const handleSave = () => {
    localStorage.setItem(`${exchange}-Cookie`, cookie);
    localStorage.setItem(`${exchange}-Mh`, mh);
  };

  return (
    <div className="keyrecord-container">
      <div><h3 className="keyrecord-title-exchange">{exchange}</h3></div>
      
      <CustomInput title={`${exchange} Mh`} value={mh} onChange={handleMhChange} />
      <CustomInput title={`${exchange} Cookie`} value={cookie} onChange={handleCookieChange} />
      <button
        className="keyrecord-saved-button"
        type="submit"
        onClick={handleSave}
      >
        Kaydet
      </button>
    </div>
  );
};

export default MexcMarketV2;
