import { useNavigate } from "react-router-dom";
import {
    Walletgeticon,
    Walletsellicon,
  } from "../../assets/icons/icon";
const TransferButton = ({currency}) => {
  let navigate = useNavigate();
  return (
    <>
      <button
        className="al-icon-btn"
        onClick={() =>  navigate(`/deposit/${currency}`)}
      >
        {Walletgeticon}
      </button>
      <button
        className="sat-icon-btn"
        onClick={() =>  navigate(`/withdraw/${currency}`)}
      >
        {Walletsellicon}
      </button>
    </>
  );
};


export default TransferButton;