import "./TradingLeft.css";
import TabGroups from "../../components/TabGroups/TabGroups";
import { useApp } from "../../context/AppContext";
import RadioButton from "../../components/RadioButton/RadioButton";
import ArbitrageTable from "../../components/ArbitrageTable/ArbitrageTable";
import { useEffect, useState } from "react";

const TradingLeft = () => {
  const {
    exchangeGroups,
    arbitrageTable,
    filterGroups,
    setFilterGroups,
    selectedOption,
    setSelectedOption,
  } = useApp();

  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi için yeni state

  // Filtreleme işlevi
  useEffect(() => {
    let data = arbitrageTable;

    const normalizedFilterGroups = filterGroups.toLowerCase();

    // FilterGroups ile borsa filtresi uygulanıyor
    if (filterGroups !== "Hepsi") {
      data = data.filter(
        (item) =>
          item.stockA.toLowerCase().includes(normalizedFilterGroups) ||
          item.stockB.toLowerCase().includes(normalizedFilterGroups)
      );
    }

    // SelectedOption ile işlem türü filtresi uygulanıyor
    if (selectedOption === "Alış") {
      data = data.filter((item) =>
        item.stockA.toLowerCase().includes(normalizedFilterGroups)
      );
    } else if (selectedOption === "Satış") {
      data = data.filter((item) =>
        item.stockB.toLowerCase().includes(normalizedFilterGroups)
      );
    }

    // Arama işlevi ekleniyor
    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase();
      data = data.filter(
        (item) =>
          item.currencyA.toLowerCase().includes(normalizedSearchTerm) ||
          item.currencyB.toLowerCase().includes(normalizedSearchTerm)
      );
    }

    setFilteredData(data);
  }, [filterGroups, selectedOption, arbitrageTable, searchTerm]);

  return (
    <>
      <div className="trading__filters">
        <div className="trading__filters-left">
          <input
            type="text"
            placeholder="Arama yap..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <RadioButton
            label="Hepsi"
            name="filterOption"
            checked={selectedOption === "Hepsi"}
            onChange={() => setSelectedOption("Hepsi")}
          />
          <RadioButton
            label="Alış"
            name="filterOption"
            checked={selectedOption === "Alış"}
            onChange={() => setSelectedOption("Alış")}
          />
          <RadioButton
            label="Satış"
            name="filterOption"
            checked={selectedOption === "Satış"}
            onChange={() => setSelectedOption("Satış")}
          />
        </div>
      </div>
      <TabGroups
        data={exchangeGroups}
        setLabel={setFilterGroups}
        Index={exchangeGroups.findIndex((item) => item.label === filterGroups)}
      />
      <ArbitrageTable data={filteredData} />
    </>
  );
};

export default TradingLeft;
