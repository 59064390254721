const checkIP = async () => {
    const cacheKey = "checkIPCache";
    const cacheTimestampKey = "checkIPTimestamp";
    const eightHours = 8 * 60 * 60 * 1000; // 8 saat (milisaniye cinsinden)
  
    // Local storage'dan zaman damgasını ve veriyi al
    const cachedResponse = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
    // Eğer cache 8 saatten daha eskiyse veya cache yoksa yeni istek yap
    if (!cachedResponse || !cachedTimestamp || Date.now() - cachedTimestamp > eightHours) {
      try {
        const response = await fetch('https://auth.gangue.io/check');
        const isIPAllowed = response.ok;
  
        // Yanıtı ve zaman damgasını local storage'a kaydet
        localStorage.setItem(cacheKey, JSON.stringify(isIPAllowed));
        localStorage.setItem(cacheTimestampKey, Date.now().toString());
  
        return isIPAllowed;
      } catch (error) {
        // Ağ veya diğer hatalar durumunda
        console.error('Error during IP check:', error);
        return false;
      }
    }
  
    // Eğer cache güncelse local storage'daki veriyi döndür
    return JSON.parse(cachedResponse);
  };
  
  const IPAuthService = { checkIP };
  export default IPAuthService;
  