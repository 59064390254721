import React, { useEffect, useState } from "react";
import "./Withdraw.css";
import StepIndicator from "../../layout/StepIndicator/StepIndicator";
import { Backicon } from "../../assets/icons/icon";
import { useApp } from "../../context/AppContext";
import WalletHistoryTable from "../../components/WithdrawHistoryTable/WithdrawHistoryTable";
import SelectOptions from "../../components/SelectOptions/SelectOptions";
import { useNavigate, useParams } from "react-router-dom";

const Withdraw = () => {
  const {
    service,
    selectedExchange,
    balance,
    withdrawHistory,
    setWithdrawHistory,
    apiKey,
    apiSecret,
  } = useApp();
  const { currency } = useParams();
  let navigate = useNavigate();
  const [chainOptions, setChainOptions] = useState([]);
  const [selectedChain, setSelectedChain] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);
  const [savedAddressOptions, setSavedAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [stepIndex, setStepIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [withdrawState, setWithdrawState] = useState({
    currency: currency,
    address: "",
    amount: "",
    chain: "",
  });

  const availableBalance = balance.find(
    (item) => item.currency === currency
  )?.available;

  useEffect(() => {
  

    const fetchChainOptions = async () => {
      try {
        const response = await service.Wallet.onChain(currency);
        const validChains = response.filter((option) => option.chainName);
        setChainOptions(validChains);
        if (validChains.length > 0) {
          setSelectedChain(validChains[0]);
          setWithdrawState((prev) => ({
            ...prev,
            chain: validChains[0].chain,
          }));
        }
      } catch (error) {
        console.error("Zincir bilgileri alınırken hata oluştu:", error);
      }
    };

    const fetchSavedAddressOptions = async () => {
      try {
        const response = await service.Wallet.savedAddress(currency, {
          key: apiKey,
          secret: apiSecret,
        });

        let updatedResponse = response;

        // `selectedExchange` Gate ise ve `Gate-Publick-Ad` ile `Gate-Publick-Wallet` dolu ise, ekstra adres ekle
        const gatePublicAddress = localStorage.getItem("Gate-Publick-Ad");
        const gatePublicWallet = localStorage.getItem("Gate-Publick-Wallet");

        if (
          selectedExchange === "Gate" &&
          gatePublicAddress &&
          gatePublicWallet
        ) {
          // Extra adresi oluştur ve listeye ekle
          const extraAddress = {
            currency: "", // Tüm tokenlerde geçerli olması için boş bırakıldı
            chain: "SOL",
            address: gatePublicWallet,
            name: gatePublicAddress,
            tag: "",
            verified: "1",
          };

          // Extra adresi yanıt listesine ekle
          updatedResponse = [...response, extraAddress];
        }

        setSavedAddressOptions(updatedResponse);
      } catch (error) {
        console.error("Kayıtlı adresler alınırken hata oluştu:", error);
      }
    };

  
    fetchChainOptions();
    fetchSavedAddressOptions();
  }, [
    service,
    currency,
    apiKey,
    apiSecret,
    selectedExchange,
    setWithdrawHistory,
  ]);

  useEffect(() => {
    setSelectedAddress("");
    setAmount("");
  }, [selectedChain]);

  useEffect(() => {
    if (selectedChain) {
      const filteredAddresses = savedAddressOptions.filter(
        (address) => address.chain === selectedChain.chain
      );
      setAddressOptions(filteredAddresses);
      if (filteredAddresses.length > 0) {
        setSelectedAddress(filteredAddresses[0].address);
        setWithdrawState((prev) => ({
          ...prev,
          address: filteredAddresses[0].address,
        }));
        setStepIndex(1);
      } else {
        setSelectedAddress("");
        setStepIndex(0);
      }
    }
  }, [selectedExchange, selectedChain, savedAddressOptions]);

  useEffect(() => {
    if (amount) {
      setWithdrawState((prev) => ({ ...prev, amount }));
      setStepIndex(3);
    } else if (selectedAddress) {
      setStepIndex(2);
    } else {
      setStepIndex(1);
    }
  }, [selectedAddress, amount]);

  const handleChainSelect = (event) => {
    const chainObj = JSON.parse(event.target.value);
    setSelectedChain(chainObj);
    setWithdrawState((prev) => ({ ...prev, chain: chainObj.chain }));
  };

  const handleAddressSelect = (event) => {
    setSelectedAddress(event.target.value);
    setWithdrawState((prev) => ({ ...prev, address: event.target.value }));
  };

  const handleAmountChange = (event) => {
    const newAmount = event.target.value;
    setAmount(newAmount);
    setWithdrawState((prev) => ({ ...prev, amount: newAmount }));
  };

  const handleSubmit = async () => {
    if (
      withdrawState.currency &&
      withdrawState.address &&
      withdrawState.amount &&
      withdrawState.chain
    ) {
      const params = {
        currency: withdrawState.currency,
        address: withdrawState.address,
        amount: withdrawState.amount,
        memo: "",
        chain: withdrawState.chain,
      };

      const headers = {
        key: apiKey,
        secret: apiSecret,
      };

      try {
        console.log("Withdraw parameters:", params, headers);
        await service.Wallet.withdraw(params, headers);
        setMessage("İşlem başlatıldı");
        setIsError(false);
        setTimeout(() => setMessage(""), 2000); // 2 saniye sonra mesajı kaldır
      } catch (error) {
        console.error("Çekme işlemi sırasında hata oluştu:", error);
        const errorMessage = error.message || error;
        setMessage(`Hata oluştu: ${errorMessage}`);
        setIsError(true);
        setTimeout(() => setMessage(""), 2000); // 2 saniye sonra mesajı kaldır
      }
    }
  };

  const handleAvailableClick = () => {
    setAmount(availableBalance);
    setWithdrawState((prev) => ({ ...prev, amount: availableBalance }));
  };

  return (
    <div className="withdraw-container">
      <div className="withdraw-top">
        <div className="withdraw-left">
          <div className="withdraw-left-top">
            <span
              className="wallet-back-btn"
              onClick={() => navigate(`/wallet`)}
            >
              {Backicon}
            </span>
            <span>{currency}</span>
          </div>
          <h2>Çekme</h2>

          <div className="wallet-selectDiv">
            <label htmlFor="">Ağ Seçimi</label>
            <SelectOptions
              value={JSON.stringify(selectedChain)}
              options={chainOptions.map((option, index) => ({
                value: JSON.stringify(option),
                label: option.chainName,
                key: index,
              }))}
              handleChange={handleChainSelect}
              required={true}
            />
          </div>
          {selectedChain && (
            <div className="wallet-selectDiv">
              <label htmlFor="">Adres Seçimi</label>
              <SelectOptions
                value={selectedAddress}
                options={addressOptions.map((option, index) => ({
                  value: option.address,
                  label: `Ad: ${option.name} - Adres: ${option.address}`,
                  key: index,
                }))}
                handleChange={handleAddressSelect}
                required={true}
              />
            </div>
          )}
          {selectedAddress && (
            <div className="wallet-inputDiv">
              <label>Miktar</label>
              <input
                type="number"
                value={amount}
                onChange={handleAmountChange}
              />
              {availableBalance !== undefined && (
                <label
                  className="available-balance"
                  onClick={handleAvailableClick}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Mevcut: {availableBalance}
                </label>
              )}
            </div>
          )}
          <button className="wallet-contunio-btn" onClick={handleSubmit}>
            Devam Et
          </button>
          {message && (
            <div className={`message ${isError ? "error" : "success"}`}>
              {message}
            </div>
          )}
        </div>
        <div className="withdraw-right">
          <StepIndicator
            data={[
              { text: "Ağ Seçimi" },
              { text: "Cüzdan Adresi" },
              { text: "Miktar" },
              { text: "Onayla" },
            ]}
            activeIndex={stepIndex}
          />
        </div>
      </div>
      <div className="withdraw-bottom">
        <WalletHistoryTable data={withdrawHistory} />
      </div>
    </div>
  );
};

export default Withdraw;
