import "./NotificationPopup.css";
import React, { useState } from "react";
import TabGroups from "../components/TabGroups/TabGroups";
import { Notebookicon } from "../assets/icons/icon";

const NotificationPopUp = () => {
  const [indexNotification, setIndexNotification] = useState(1);
  const dataNotification = [
    { label: "Öne çıkanlar" },
    { label: "Bildirimler" },
    { label: "Duyurular" },
  ];

  return (
    <div className="notification-popup">
      <div className="notification-popup-title">Bildirimler ve duyurular</div>
      <TabGroups
        data={dataNotification}
        setIndex={setIndexNotification}
        Index={indexNotification}
      />
      <div className="notification-container">
      <NotificationItem
        title="Google Authenticator etkin"
        date="21.09.2023 • 14:51:20"
        text="Hesabınız için Google Authenticator güvenlik tercihini etkinleştirdiniz."
      />
      </div>
    </div>
  );
};


const NotificationItem = ({ title, date, text }) => {
  return (
    <div className="notification-container">
      <div className="notification-icon">{Notebookicon}</div>
      <div className="notification-title">
        <p>{title}</p>
        <p className="notification-date">{date}</p>
        <p className="notification-text">{text}</p>
      </div>
    </div>
  );
};
export default NotificationPopUp;
