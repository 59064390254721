import React, {  useRef } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  Piyasalaricon,
  Cuzdanicon,
  Profilicon,
} from "../../assets/icons/icon";



const Footer = () => {
  const FooterRef = useRef(null);

  return (
    <div ref={FooterRef} className="footer mobile-show">
        <Link to="/piyasalar" className="footer-nav-link">
          Piyasalar {Piyasalaricon}
        </Link>
        <Link to="/wallet" className="footer-nav-link">
          Cüzdan {Cuzdanicon}
        </Link>
        <Link to={"/profile"} className="footer-nav-link">
          Profil {Profilicon}
        </Link>
      </div>
  );
};

export default Footer;
