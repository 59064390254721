import { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const generatePayload = (method, param) => {
  return JSON.stringify({
    method: method,
    params: param,
  });
};

export const useMexcPublicWebsocket = (isActive) => {
  const ws = useRef(null);
  const [tickers, setTickers] = useState([]);
  const [publicTrades, setPublicTrades] = useState([]);
  const [orderBook, setOrderBook] = useState([]);
  const [searchParams] = useSearchParams();
  const initialCurrencyRef = useRef(null);
  const initialUnsubscribedRef = useRef(false);
  const [stockA, setStockA] = useState(null);

  useEffect(() => {
    // URL'den stockA'yı alıyoruz ve state'e kaydediyoruz
    const stockParam = searchParams.get("stockA");
    setStockA(stockParam);
  }, [searchParams]);

  useEffect(() => {
    let pingInterval;
    let socket; // WebSocket instance'ını yerel değişkende saklıyoruz

    if (!isActive) {
      return;
    }

    // URL'den currency ve stock bilgilerini alıyoruz
    const currencyParam = searchParams.get("currencyA");
    const stockParam = stockA; // Güncel stockA değerini kullanıyoruz

    // Eğer stockA "Mexc" ise, abonelikleri başlat
    if (stockParam === "Mexc" && currencyParam) {
      const initialCurrency = currencyParam + "USDT";
      initialCurrencyRef.current = initialCurrency;

      // WebSocket bağlantısını kur
      socket = new WebSocket(`wss://wbs.mexc.com/ws`);
      ws.current = socket; // ws.current'e atama yapıyoruz

      socket.onopen = () => {
        console.log("Mexc Public connection.");

        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }

        if (socket.readyState === WebSocket.OPEN) {
          // İlk token için abonelik başlat
          socket.send(
            JSON.stringify({
              method: "SUBSCRIPTION",
              params: [
                `spot@public.miniTicker.v3.api@${initialCurrency}@UTC+8`,
                `spot@public.deals.v3.api@${initialCurrency}`,
                `spot@public.limit.depth.v3.api@${initialCurrency}@10`,
              ],
            })
          );
        }

        // Ping gönderimi
        const PING_INTERVAL = 10 * 1000; // 10 saniye
        pingInterval = setInterval(() => {
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ method: "PING" }));
          }
        }, PING_INTERVAL);
      };

      socket.onmessage = (event) => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }

        const data = JSON.parse(event.data);

        if (data.s && data.c.includes(`spot@public.miniTicker.v3.api@`)) {
          let newData = {
            cp: "currency_pair",
            r: data.d.r,
            p: data.d.p,
            l: data.d.l,
            h: data.d.h,
            v: data.d.v,
            q: data.d.q,
          };
          setTickers(newData);
        }
        if (data.s && data.c.includes(`spot@public.deals.v3.api@`)) {
          let newData = {
            t: data.d.deals[0].t,
            cp: "currency_pair",
            s: data.d.deals[0].S,
            p: data.d.deals[0].p,
            v: data.d.deals[0].v,
          };
          setPublicTrades((prevTrades) => {
            const newTrades = [newData, ...prevTrades];
            // Liste uzunluğunu 20 ile sınırla
            return newTrades.slice(0, 20);
          });
        }

        if (data.s && data.c.includes(`spot@public.limit.depth.v3.api@`)) {
          let asks = [];
          let bids = [];

          let buyTotalVolume = 0;
          let sellTotalVolume = 0;

          for (let i = 0; i < data.d.asks.length; i++) {
            const buyPrice = Number(data.d.asks[i].p);
            const buyVolume = Number(data.d.asks[i].v);
            buyTotalVolume += buyVolume;
            asks.push({
              p: buyPrice,
              v: buyVolume,
              tv: buyTotalVolume,
            });
          }
          for (let i = 0; i < data.d.bids.length; i++) {
            const sellPrice = Number(data.d.bids[i].p);
            const sellVolume = Number(data.d.bids[i].v);
            sellTotalVolume += sellVolume;
            bids.push({
              p: sellPrice,
              v: sellVolume,
              tv: sellTotalVolume,
            });
          }
          const updatedOrderBook = {
            cp: "currency_pair",
            asks: asks,
            bids: bids,
          };

          setOrderBook(updatedOrderBook);
        }
      };

      socket.onerror = (error) => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }
        console.log("Mexc Public closed");
        clearInterval(pingInterval);
        ws.current = null;
      };
    } else {
      // stockA "Mexc" değilse, mevcut bağlantıyı kapat
      if (ws.current) {
        ws.current.close();
        ws.current = null;
        initialUnsubscribedRef.current = false; // İlk iptal durumunu sıfırla
        initialCurrencyRef.current = null;
        console.log("Mexc Public connection closed due to stockA change.");
      }
      // State'leri sıfırla
      setTickers([]);
      setPublicTrades([]);
      setOrderBook([]);
    }

    return () => {
      if (ws.current === socket && ws.current) {
        ws.current.close();
        ws.current = null;
      }
      clearInterval(pingInterval);
    };
  }, [isActive, stockA, searchParams]);

  // Mesaj gönderme fonksiyonu, ilk aboneliği iptal eder ve yeni mesajı gönderir
  const sendMexcSocketMessage = useCallback((method, param) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      // İlk aboneliği iptal etme kontrolü
      if (!initialUnsubscribedRef.current) {
        const initialCurrency = initialCurrencyRef.current;
        if (initialCurrency) {
          ws.current.send(
            generatePayload("UNSUBSCRIPTION", [
              `spot@public.miniTicker.v3.api@${initialCurrency}@UTC+8`,
              `spot@public.deals.v3.api@${initialCurrency}`,
              `spot@public.limit.depth.v3.api@${initialCurrency}@10`,
            ])
          );
        }
        initialUnsubscribedRef.current = true; // İlk iptal gerçekleşti
      }
      const message = generatePayload(method, param);
      ws.current.send(message);
    }
  }, []);

  return { tickers, publicTrades, orderBook, sendMexcSocketMessage };
};
