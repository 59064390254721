import axios from 'axios';

// API URL tanımı
const API_URL = "https://gate.gangue.io/";
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

// API isteklerini yapacak temel fonksiyon
const sendRequest = async (method, path, data = {}, params = {}, headers = {}) => {
  try {
    console.log(data)
    console.log(params)
    const response = await axiosInstance({
      method,
      url: path,
      params,
      data,
      headers
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error);
    throw error;
  }
};

export const Spot = {
  // Sipariş oluşturma
  // type: 'limit' veya 'market'
  // currencyPair: 'BTC_USDT' gibi döviz çifti
  // side: 'buy' veya 'sell'
  // amount: Miktar
  // total: Toplam tutar (type 'market' ve side 'buy' için gerekli)
  // price: Fiyat (type 'limit' için gerekli)
  // headers: { key: 'api-key', secret: 'api-secret' }
  createOrder: async (type, currencyPair, side, amount, total, price, headers) => {
    const orderData = {
      type,
      currency_pair: currencyPair,
      side,
      amount,
      total,
      price
    };
    return await sendRequest('POST', '/spot/createOrder', orderData, {}, headers);
  },

  // Siparişi iptal etme
  // id: Sipariş ID'si
  // currencyPair: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  cancelOrder: async (id, currencyPair, headers) => {
    return await sendRequest('DELETE', `/spot/cancelOrder/`, {}, { id:id,currency_pair: currencyPair }, headers);
  },

  // Açık siparişleri listeleme
  // headers: { key: 'api-key', secret: 'api-secret' }
  openOrders: async (currencyPair, headers) => {
    return await sendRequest('GET', '/spot/openOrders', {}, { currency_pair: currencyPair}, headers);
  },

  // Belirli bir siparişi getirme
  // orderId: Sipariş ID'si
  // currency: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  getOrder: async (orderId, currency, headers) => {
    return await sendRequest('GET', `/spot/orders/${orderId}`, {}, { currency_pair: currency }, headers);
  },

  // Ticker bilgilerini getirme
  // currency: Opsiyonel, 'BTC_USDT' gibi döviz çifti
  getTickers: async (currency) => {
    const queryParams = currency ? { currency_pair: currency } : {};
    return await sendRequest('GET', '/spot/getTickers', {}, queryParams);
  },

  // Sipariş geçmişini listeleme
  // currencyPair: 'BTC_USDT' gibi döviz çifti
  // headers: { key: 'api-key', secret: 'api-secret' }
  orderHistory: async (currencyPair, headers) => {
    return await sendRequest('GET', '/spot/orderHistory', {}, { currency_pair: currencyPair}, headers);
  },

  // En yüksek değişim yüzdesine sahip tickers'ı getirme
  // tickers: Ticker arrayi
  getHighLights: (tickers) => {
    return tickers
      .sort(
        (a, b) => Math.abs(parseFloat(b.change_percentage)) - Math.abs(parseFloat(a.change_percentage))
      )
      .slice(0, 20);
  }
};

