import React, { createContext, useContext, useEffect, useRef } from "react";
import { useApp } from "./AppContext";
import { useGatePrivateWebsocket } from "../hooks/useGatePrivateWebsocket";
import { useMexcPrivateWebsocket } from "../hooks/useMexcPrivateWebsocket";
import { useArbitrageData } from "../hooks/useArbitrageData";
import { useAuth } from "./AuthContext";
import { useListenKey } from "../hooks/useListenkey";
import { useGatePublicWebsocket } from "../hooks/useGatePublicWebsocket";
import { useMexcPublicWebsocket } from "../hooks/useMexcPublicWebsocket";
import { getService } from "../service/ServiceManager";

const WsContext = createContext();
export const useWs = () => useContext(WsContext);

export const WsProvider = ({ children }) => {
  const { isTokenValid } = useAuth();
  const {
    selectedExchange,
    setBalance,
    setOrders,
    setWithdrawHistory,
    setDepositHistory,
    setTickers,
    setPublicTrades,
    setOrderBook,
    tradeToken,
    setDefaultSymbol,
    setDefaultSymbolV2
  } = useApp();
  const mexcService = getService("Mexc");
  useArbitrageData(isTokenValid);
  const apiKey = localStorage.getItem("Mexc-Key");
  const apiSecret = localStorage.getItem("Mexc-Secret");


  useEffect(() => {
    mexcService.Spot.defaultSYMBOL({ key: apiKey, secret: apiSecret })
      .then((data) => {
        setDefaultSymbol(data.defaultSYMBOL);
        setDefaultSymbolV2(data.defaultSYMBOLV2);
      })
      .catch((error) => {
        console.error("Veri alınırken hata oluştu:", error);
      });
    // eslint-disable-next-line 
  }, []);
  

  const {
    balance: gateBalance,
    orders: gateOrders,
    withdrawHistory: gateWithdrawHistory,
    depositHistory: gateDepositHistory,
  } = useGatePrivateWebsocket(isTokenValid);

  const {
    tickers: gateTickers,
    publicTrades: gatePublicTrades,
    orderBook: gateOrderBook,
    sendGateSocketMessage,
  } = useGatePublicWebsocket(isTokenValid);
  const previousGateTokenRef = useRef();

  // Gate için WebSocket işlemleri
  useEffect(() => {
    if (isTokenValid && tradeToken.stockA === "Gate") {
      // Eski aboneliği iptal et
      if (
        previousGateTokenRef.current &&
        previousGateTokenRef.current !== tradeToken.currencyA
      ) {
        sendGateSocketMessage(`spot.tickers`, "unsubscribe", [
          previousGateTokenRef.current + "_USDT",
        ]);
        sendGateSocketMessage(`spot.trades`, "unsubscribe", [
          previousGateTokenRef.current + "_USDT",
        ]);
        sendGateSocketMessage(`spot.order_book`, "unsubscribe", [
          previousGateTokenRef.current + "_USDT",
          "10",
          "1000ms",
        ]);
      }
      // Yeni token için abonelik başlat
      sendGateSocketMessage(`spot.tickers`, "subscribe", [
        tradeToken.currencyA + "_USDT",
      ]);
      sendGateSocketMessage(`spot.trades`, "subscribe", [
        tradeToken.currencyA + "_USDT",
      ]);
      sendGateSocketMessage(`spot.order_book`, "subscribe", [
        tradeToken.currencyA + "_USDT",
        "10",
        "1000ms",
      ]);
      // Referansı güncelle
      previousGateTokenRef.current = tradeToken.currencyA;
    }
  }, [
    tradeToken.currencyA,
    selectedExchange,
    tradeToken.stockA,
    isTokenValid,
    sendGateSocketMessage,
  ]);

  const listenKey = useListenKey();
  const {
    balance: mexcBalance,
    orders: mexcOrders,
    withdrawHistory: mexcWithdrawHistory,
    depositHistory: mexcDepositHistory,
  } = useMexcPrivateWebsocket(isTokenValid && listenKey, listenKey);

  const {
    tickers: mexcTickers,
    publicTrades: mexcPublicTrades,
    orderBook: mexcOrderBook,
    sendMexcSocketMessage,
  } = useMexcPublicWebsocket(isTokenValid);
  
  const previousMexcTokenRef = useRef();
  // Mexc için WebSocket işlemleri
  useEffect(() => {
    if (isTokenValid && tradeToken.stockA === "Mexc") {
      // Eski aboneliği iptal et
      if (
        previousMexcTokenRef.current &&
        previousMexcTokenRef.current !== tradeToken.currencyA
      ) {
        sendMexcSocketMessage("UNSUBSCRIPTION", [
          `spot@public.miniTicker.v3.api@${
            previousMexcTokenRef.current + "USDT"
          }@UTC+8`,
        ]);
        sendMexcSocketMessage("UNSUBSCRIPTION", [
          `spot@public.deals.v3.api@${previousMexcTokenRef.current + "USDT"}`,
        ]);
        sendMexcSocketMessage("UNSUBSCRIPTION", [
          `spot@public.limit.depth.v3.api@${
            previousMexcTokenRef.current + "USDT"
          }@10`,
        ]);
      }
      // Yeni token için abonelik başlat
      sendMexcSocketMessage("SUBSCRIPTION", [
        `spot@public.miniTicker.v3.api@${tradeToken.currencyA + "USDT"}@UTC+8`,
      ]);
      sendMexcSocketMessage("SUBSCRIPTION", [
        `spot@public.deals.v3.api@${tradeToken.currencyA + "USDT"}`,
      ]);
      sendMexcSocketMessage("SUBSCRIPTION", [
        `spot@public.limit.depth.v3.api@${tradeToken.currencyA + "USDT"}@10`,
      ]);
      // Referansı güncelle
      previousMexcTokenRef.current = tradeToken.currencyA;
    }
  }, [
    tradeToken.currencyA,
    tradeToken.stockA,
    selectedExchange,
    isTokenValid,
    sendMexcSocketMessage,
  ]);

  useEffect(() => {
    if (selectedExchange === "Gate") {
      setTickers(gateTickers);
      setPublicTrades(gatePublicTrades);
      setOrderBook(gateOrderBook);
    }
    if (selectedExchange === "Mexc") {
      setTickers(mexcTickers);
      setPublicTrades(mexcPublicTrades);
      setOrderBook(mexcOrderBook);
    }
  }, [
    selectedExchange,
    gateTickers,
    gatePublicTrades,
    gateOrderBook,
    mexcTickers,
    mexcPublicTrades,
    mexcOrderBook,
    setTickers,
    setPublicTrades,
    setOrderBook,
  ]);

  useEffect(() => {
    if (selectedExchange === "Gate") {
      setBalance(gateBalance);
      setOrders(gateOrders);
      setWithdrawHistory(gateWithdrawHistory);
      setDepositHistory(gateDepositHistory);
    }
    if (selectedExchange === "Mexc") {
      setBalance(mexcBalance);
      setOrders(mexcOrders);
      setWithdrawHistory(mexcWithdrawHistory);
      setDepositHistory(mexcDepositHistory);
    }
  }, [
    selectedExchange,
    gateBalance,
    gateOrders,
    gateWithdrawHistory,
    gateDepositHistory,
    mexcBalance,
    mexcOrders,
    mexcWithdrawHistory,
    mexcDepositHistory,
    setBalance,
    setOrders,
    setWithdrawHistory,
    setDepositHistory,
  ]);

  return <WsContext.Provider value={{}}>{children}</WsContext.Provider>;
};
