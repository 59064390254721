
import { Grayroundicon } from "../../assets/icons/icon";
import "./ProfileHeader.css"
const ProfileHeader = ({title,subtitle}) => {
  return (
    <>
      <h2>{title}</h2>
      <div className="userinfo-header-container">
        {title}
        <span className="userinfo-header-icon">{Grayroundicon}</span>
        <span className="userinfo-header-subtitle">{subtitle}</span>
      </div>
    </>
  );
};
export default ProfileHeader;
