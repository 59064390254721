import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Login from "./pages/Login/Login";
import Header from "./layout/Header/Header";
import Piyasalar from "./pages/Piyasalar/Piyasalar";
import Footer from "./layout/Footer/Footer";
import Forbidden from "./layout/Forbidden/Forbidden";
import Profile from "./pages/Profile/Profile";
import Wallet from "./pages/Wallet/Wallet";
import History from "./pages/History/History";
import Trading from "./pages/Trading/Trading";
import "./App.css";
import Swap from "./pages/Swap/Swap";
import { AppProvider } from "./context/AppContext";
import { WsProvider } from "./context/WsContext";
import { AuthProvider } from "./context/AuthContext";
import Deposit from "./pages/Deposit/Deposit";
import Withdraw from "./pages/Withdraw/Withdraw";


const AppWrapper = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppProvider>
          <WsProvider>
            <Layout>
              <App />
            </Layout>
          </WsProvider>
        </AppProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const Layout = ({ children }) => {
  const location = useLocation();
  const hideOnRoutes = ["/404", "/swap"];
  const showHeaderAndFooter = !hideOnRoutes.includes(location.pathname);

  return (
    <div className="app-container">
      {showHeaderAndFooter && <Header />}
      {children}
   
      {showHeaderAndFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Piyasalar />} />
      <Route path="/login" element={<Login />} />
      <Route path="/piyasalar" element={<Piyasalar />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/history" element={<History />} />
      <Route path="/trading/:exchange/:currency" element={<Trading />} />
      <Route path="/withdraw/:currency" element={<Withdraw />} />
      <Route path="/deposit/:currency" element={<Deposit />} />
      <Route path="/404" element={<Forbidden />} />
    </Routes>
  );
};

export default AppWrapper;
