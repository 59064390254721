import React from "react";
import defaultStyles from "./OrderBookTable.module.css";

const OrderBookTable = ({
  data,
  tickers,
  styles = defaultStyles,
  buyTradeRef,
  sellTradeRef,
  noDataContent = <div>Veri bulunamadı</div>,
  noRowsContent = <div>Gösterilecek satır yok</div>,
}) => {
  const handleRowClick = (type, row) => {
    if (type === "buy" && buyTradeRef.current) {
      const maxAmount = Math.min(
        row.tv,
        buyTradeRef.current.tokenBalance.available / row.p
      );
      buyTradeRef.current.setPrice(row.p);
      buyTradeRef.current.setAmount(maxAmount);
      buyTradeRef.current.setTotal(maxAmount * row.p);
    } else if (type === "sell" && sellTradeRef.current) {
      const maxAmount = Math.min(
        row.tv,
        sellTradeRef.current.tokenBalance.available
      );
      sellTradeRef.current.setPrice(row.p);
      sellTradeRef.current.setAmount(maxAmount);
      sellTradeRef.current.setTotal(maxAmount * row.p);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <div className={styles.tr}>
          <span className={styles.th}>Fiyat(USDT)</span>
          <span className={styles.th}>Miktar</span>
          <span className={`${styles.th} ${styles.mobile_hidden}`}>Toplam(USDT)</span>
        </div>
      </div>
      <div className={styles.tbody}>
        {data && data.asks && data.asks.length > 0
          ? [...data.asks].reverse().map(
              (
                row,
                rowIndex // `asks` dizisinin bir kopyasını alıp ters çevir
              ) => (
                <div 
                  className={styles.tr} 
                  style={{background: calculateGradient(row.tv, 'buy', data.asks[data.asks.length - 1].tv)}} 
                  key={rowIndex}
                  onClick={() => handleRowClick('buy', row)}
                >
                  <span className={`${styles.td} ${styles.negatif}`}>{row.p}</span>
                  <span className={styles.td}>{row.tv.toFixed(3)}</span>
                  <span className={`${styles.td} ${styles.mobile_hidden}`}>{(row.tv * row.p).toFixed(3)}</span>
                </div>
              )
            )
          : noRowsContent}

        <div className={styles.tr}>
          <span className={styles.td}>{tickers.p}</span>
        </div>
        {data && data.bids && data.bids.length > 0
          ? data.bids.map((row, rowIndex) => (
              <div 
                className={styles.tr} 
                style={{background: calculateGradient(row.tv, 'sell', data.bids[data.bids.length - 1].tv)}} 
                key={rowIndex}
                onClick={() => handleRowClick('sell', row)}
              >
                <span className={`${styles.td} ${styles.pozitif}`}>{row.p}</span>
                <span className={styles.td}>{row.tv.toFixed(3)}</span>
                <span className={`${styles.td} ${styles.mobile_hidden}`}>{(row.tv * row.p).toFixed(3)}</span>
              </div>
            ))
          : noRowsContent}
      </div>
    </div>
  );
};

function calculateGradient(volume, type, maxVolume) {
  const opacity = 0.15; // Sabit opaklık değeri

  // Hacme göre renklendirilecek uzunluğu hesapla
  let length = Math.min(75, (volume / maxVolume) * 100 * 0.75);  // Yüzde 75'i geçmemesi için

  // Renk tipine göre gradient oluştur
  if (type === 'sell') {
    return `linear-gradient(to left, rgba(0, 128, 0, ${opacity}) ${length}%, transparent ${length}%)`;
  } else if (type === 'buy') {
    return `linear-gradient(to left, rgba(255, 0, 0, ${opacity}) ${length}%, transparent ${length}%)`;
  }

  return ''; // Geçersiz bir tip gelirse boş dön
}

export default OrderBookTable;
