import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import useIPCheck from '../hooks/useIPCheck';
import Loading from '../layout/Loading/Loading';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { isAllowed, isLoading: originalIsLoading } = useIPCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.removeItem('token');
          localStorage.removeItem('tokenLastChecked');
      navigate('/login', { replace: true });
      return;
    }

    const lastChecked = parseInt(localStorage.getItem('tokenLastChecked'), 10) || 0;
    const now = Date.now();

    const checkToken = async () => {
      try {
        const response = await axios.post('https://auth.gangue.io/check-token', { sessionToken: token });
        if (response.status === 200) {
          setIsTokenValid(true);
          localStorage.setItem('tokenLastChecked', now.toString());
        } else {
          setIsTokenValid(false);
          localStorage.removeItem('token');
          localStorage.removeItem('tokenLastChecked');
          navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error("Token validation error", error);
        setIsTokenValid(false);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenLastChecked');
        navigate('/login', { replace: true });
      }
    };

    if (now - lastChecked > 8 * 60 * 60 * 1000) { // 8 saat = 8 * 60 * 60 * 1000 milisaniye
      checkToken();
    } else {
      setIsTokenValid(!!token);
    }
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(originalIsLoading);
    }, 1000); // 1 saniye bekleme süresi, 2 saniye için 2000 kullanılabilir.

    return () => clearTimeout(timer);
  }, [originalIsLoading]);

  useEffect(() => {
    if (isLoading) return;

    if (!isAllowed) {
      navigate('/404', { replace: true });
    } else if (!isTokenValid && location.pathname !== "/login") {
      navigate('/login', { replace: true });
    } else if (isTokenValid && location.pathname === "/login") {
      navigate('/', { replace: true });
    }
  }, [isLoading, isAllowed, isTokenValid, location.pathname, navigate]);

  if (isLoading) return <Loading />;

  return (
    <AuthContext.Provider value={{ isTokenValid }}>
      {children}
    </AuthContext.Provider>
  );
};
