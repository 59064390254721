import axios from "axios";

// API URL tanımı
const API_URL = "https://jup.gangue.io/";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API isteklerini yapacak temel fonksiyon
const sendRequest = async (method, path, headers = {}) => {
  try {
    const response = await axiosInstance({
      method,
      url: path,

      headers: {
        "Content-Type": "application/json",
        key: headers.key,
        secret: headers.secret,
      },
    });

    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error.response.data);
    throw error;
  }
};

export const Wallet = {
  // Çekim geçmişi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.withdrawHistory({ key: 'api-key', secret: 'api-secret' })
  withdrawHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/withdrawHistory", headers);
  },

  // Mevduat geçmişi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.depositHistory({ key: 'api-key', secret: 'api-secret' })
  depositHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/depositHistory", headers);
  },

  // Hesap bakiyesi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Wallet.accountBalance({ key: 'api-key', secret: 'api-secret' })
  accountBalance: async (headers) => {
    return await sendRequest("GET", "/wallet/accountBalance", headers);
  },
};
