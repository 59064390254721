import { useEffect, useRef, useState } from "react";
import { getService } from "../service/ServiceManager";

const generatePayload = (method, param) => {
  return JSON.stringify({
    method: method,
    params: [param],
  });
};

export const useMexcPrivateWebsocket = (isActive, listenKey) => {
  const ws = useRef(null);
  const [orders, setOrders] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const [balance, setBalance] = useState([]);
  const apiKey = localStorage.getItem("Mexc-Key");
  const apiSecret = localStorage.getItem("Mexc-Secret");
  const mexcService = getService("Mexc");
  useEffect(() => {
    let pingInterval;

    if (!isActive) {
      return;
    }
    // WebSocket bağlantısını kur
    ws.current = new WebSocket(`wss://wbs.mexc.com/ws?listenKey=${listenKey}`);
    ws.current.onopen = () => {
      console.log("Mexc Private connection.");
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          generatePayload("SUBSCRIPTION", "spot@private.orders.v3.api")
        );
        ws.current.send(
          generatePayload("SUBSCRIPTION", "spot@private.account.v3.api")
        );
      }

      // Ping gönderimi
      const PING_INTERVAL = 30 * 1000; // 30 seconds
      pingInterval = setInterval(() => {
        if (ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ method: "PING" }));
        }
      }, PING_INTERVAL);
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.code === 0 && data.msg === "spot@private.account.v3.api") {
        mexcService.Wallet.accountBalance({ key: apiKey, secret: apiSecret })
          .then(setBalance)
          .catch((error) => {
            console.error("Hesap bakiyesi alınırken hata oluştu:", error);
          });
        mexcService.Wallet.withdrawHistory({ key: apiKey, secret: apiSecret })
          .then(setWithdrawHistory)
          .catch((error) => {
            console.error("Çekim geçmişi alınırken hata oluştu:", error);
          });
        mexcService.Wallet.depositHistory({ key: apiKey, secret: apiSecret })
          .then(setDepositHistory)
          .catch((error) => {
            console.error("Yatırım geçmişi alınırken hata oluştu:", error);
          });
      }
      if (data.c === "spot@private.account.v3.api") {
        const result = data;

        setBalance((prevBalances) => {
          const index = prevBalances.findIndex(
            (item) => item.currency === result.d.a
          );
          if (index === -1) {
            // Coin mevcut değilse yeni coin ekle
            return [
              ...prevBalances,
              {
                currency: result.d.a,
                available: result.d.f,
                locked: result.d.l,
              },
            ];
          }
          const newBalances = [...prevBalances];
          newBalances[index] = {
            ...newBalances[index],
            available: result.d.f,
            locked: result.d.l,
          };
          return newBalances;
        });
        


        if (data.d.o === "WITHDRAW") {
          const fetchWithdrawHistory = () => {
            mexcService.Wallet.withdrawHistory({
              key: apiKey,
              secret: apiSecret,
            })
              .then(setWithdrawHistory)
              .catch((error) => {
                console.error("Çekim geçmişi alınırken hata oluştu:", error);
              });
          };

          // 10 saniye sonra istek at
          setTimeout(() => {
            fetchWithdrawHistory();
          }, 10000);

          // 1 dakika 10 saniye sonra istek at
          setTimeout(() => {
            fetchWithdrawHistory();
          }, 70000);
        }
        if (data.d.o === "DEPOSIT ") {
          const fetchDepositHistory = () => {
            mexcService.Wallet.depositHistory({
              key: apiKey,
              secret: apiSecret,
            })
              .then(setDepositHistory)
              .catch((error) => {
                console.error("Yatırım geçmişi alınırken hata oluştu:", error);
              });
          };

          // 10 saniye sonra istek at
          setTimeout(() => {
            fetchDepositHistory();
          }, 10000);

          // 1 dakika 10 saniye sonra istek at
          setTimeout(() => {
            fetchDepositHistory();
          }, 70000);
        }
        
      }

      if (data.c === "spot@private.orders.v3.api") {
        const result = data;
        let timestamp = new Date(result.d.O);

        let types = {
          1: "limit",
          2: "post-only",
          3: "immediate-or-cancel",
          4: "fill-or-kill",
          5: "market",
          6: "stop-limit",
        };
        let sides = {
          1: "buy",
          2: "sell",
        };
        let events = {
          1: "put",
          2: "full-finish",
          3: "update",
          4: "finish",
          5: "finish",
        };
        let time = timestamp.toISOString().replace("T", " ").slice(0, 19);
        let order = {
          id: result.d.i,
          createTime: time,
          side: result.d.S === 1 ? "BUY" : "SELL",
          currency_pair: result.s,
          type: types[result.d.o],
          price: result.d.p,
          amount: result.d.v,
          left: result.d.lv || 0,
          side: sides[result.d.S],
          total: result.d.ca,
          event: events[result.d.s],
        };

        setOrders((prevOrders) => [...prevOrders, order]);
        if (events[result.d.s] === "finish") {
          setOrders((prevOrders) =>
            prevOrders.filter((o) => o.id !== order.id)
          );
        } else if (events[result.d.s] === "full-finish") {
          setOrders((prevOrders) =>
            prevOrders.filter((o) => o.id !== order.id)
          );
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("Mexc Private closed.");
      clearInterval(pingInterval);
      ws.current = null;
    };

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      clearInterval(pingInterval);
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]); // isActive buraya eklenmeli

  return { orders, withdrawHistory, depositHistory, balance };
};
