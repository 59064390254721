import "./SelectOptions.css";

const SelectOptions = ({ title, value, options, handleChange,key }) => {
  return (
    <div className="custom-select">
      <label className="custom-select-label" htmlFor={title}>
        {title}
      </label>
      <select
        id={title}
        value={value}
        onChange={handleChange}
        className="custom-select-element"
      >
        {options.map((option, index) => (
          <option key={key|| index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectOptions;
