import { useEffect, useRef } from "react";
import { useApp } from "../context/AppContext";

export const useArbitrageData = (isActive) => {
  const { setArbitrageTable ,jupiterSend} = useApp();
  const wsRef = useRef(null);

  useEffect(() => {
    if (!isActive) return;

    const ws = new WebSocket("wss://arb.gangue.io");
    wsRef.current = ws;

    ws.onopen = () => {
      console.log("Arbitrage WebSocket connection established.");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setArbitrageTable([...data]);
    };

    ws.onerror = (error) => {
      console.error("WebSocket hatası:", error);
    };

    ws.onclose = () => {
      console.log("Arbitrage WebSocket connection closed.");
      wsRef.current = null;
    };

    return () => {
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    const isValidJupiterSend = jupiterSend && Object.keys(jupiterSend).length > 0;

    if (isValidJupiterSend && wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(jupiterSend));
    }
  }, [jupiterSend]);
};
