import React, { useState } from 'react';
import "./CreateTokenPopup.css";
const CreateTokenPopup = ({ onClose, onSubmit,retrieveTokens }) => {
  const [formData, setFormData] = useState({
    currency: '',
    chainName: '',
    withdraw_disabled: false,
    deposit_disabled: false,
    withdrawFee: '',
    withdrawMin: '',
    contractAddress: '',
    logoURI:'',
    decimals:''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.currency || !formData.chainName) {
      alert('Currency ve chain alanları zorunludur.');
      return;
    }
    onSubmit(formData).then(() => {
      alert('Token başarıyla oluşturuldu.');
      retrieveTokens();
      onClose();
    }).catch(error => {
      alert('Token oluşturulurken bir hata oluştu: ' + error.message);
    });
  };

  return (
    <div className="create-token-popup">
      <div className="popup-overlay" onClick={onClose}></div>
      <div className="popup-content">
        <h2>Yeni Token Oluştur</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Currency:
            <input type="text" name="currency" value={formData.currency} onChange={handleChange} required />
          </label>
          <label>
            Chain Name:
            <input type="text" name="chainName" value={formData.chainName} onChange={handleChange}  />
          </label>
          <label>
            Withdraw Disabled:
            <input type="checkbox" name="withdraw_disabled" checked={formData.withdraw_disabled} onChange={handleChange} />
          </label>
          <label>
            Deposit Disabled:
            <input type="checkbox" name="deposit_disabled" checked={formData.deposit_disabled} onChange={handleChange} />
          </label>
          <label>
            Withdraw Fee:
            <input type="text" name="withdrawFee" value={formData.withdrawFee} onChange={handleChange} />
          </label>
          <label>
            Withdraw Minimum:
            <input type="text" name="withdrawMin" value={formData.withdrawMin} onChange={handleChange} />
          </label>
          <label>
            Contract Address:
            <input type="text" name="contractAddress" value={formData.contractAddress} onChange={handleChange} />
          </label>
          <label>
            Decimals:
            <input type="text" name="contractAddress" value={formData.decimals} onChange={handleChange} />
          </label>
          <label>
            Logo URI:
            <input type="text" name="contractAddress" value={formData.logoURI} onChange={handleChange} />
          </label>
          <button type="submit">Kaydet</button>
          <button type="button" onClick={onClose}>İptal</button>
        </form>
      </div>
    </div>
  );
};

export default CreateTokenPopup;
