import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import { getService } from "../service/ServiceManager";

const generatePayload = (api_key, api_secret, channel, event, payload) => {
  const timestamp = Math.floor(Date.now() / 1000);
  const signatureString = `channel=${channel}&event=${event}&time=${timestamp}`;
  const signature = CryptoJS.HmacSHA512(signatureString, api_secret).toString(
    CryptoJS.enc.Hex
  );
  return JSON.stringify({
    time: timestamp,
    channel: channel,
    event: event,
    payload: payload,
    auth: { method: "api_key", KEY: api_key, SIGN: signature },
  });
};

export const useGatePrivateWebsocket = (isActive) => {
  const ws = useRef(null);
  const [orders, setOrders] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const [balance, setBalance] = useState([]);

  const apiKey = localStorage.getItem("Gate-Key");
  const apiSecret = localStorage.getItem("Gate-Secret");
  const gateService = getService("Gate");

  useEffect(() => {
    let pingInterval;

    if (!apiKey || !apiSecret) {
      return;
    }
    if (!isActive) {
      return;
    }

    if (!ws.current) {
      ws.current = new WebSocket("wss://api.gateio.ws/ws/v4/");
      ws.current.onopen = () => {
        console.log("Gate Private connection.");
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            generatePayload(apiKey, apiSecret, "spot.balances", "subscribe", [])
          );
          ws.current.send(
            generatePayload(apiKey, apiSecret, "spot.orders", "subscribe", [
              "!all",
            ])
          );
        }

        pingInterval = setInterval(() => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(
              JSON.stringify({
                time: Math.floor(Date.now() / 1000),
                channel: "spot.ping",
              })
            );
          }
        }, 30 * 1000); // 30 seconds
      };
    }

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.event === "subscribe" && data.channel === "spot.balances") {
        gateService.Wallet.accountBalance({ key: apiKey, secret: apiSecret })
          .then(setBalance)
          .catch((error) => {
            console.error("Hesap bakiyesi alınırken hata oluştu:", error);
          });
        gateService.Wallet.withdrawHistory({ key: apiKey, secret: apiSecret })
          .then(setWithdrawHistory)
          .catch((error) => {
            console.error("Çekim geçmişi alınırken hata oluştu:", error);
          });
        gateService.Wallet.depositHistory({ key: apiKey, secret: apiSecret })
          .then(setDepositHistory)
          .catch((error) => {
            console.error("Yatırım geçmişi alınırken hata oluştu:", error);
          });
      }
      if (data.event === "update" && data.channel === "spot.balances") {
        const result = data.result[0];
        setBalance((prevBalances) => {
          const index = prevBalances.findIndex(
            (item) => item.currency === result.currency
          );
          if (index === -1) {
            // Coin mevcut değilse yeni coin ekle
            return [
              ...prevBalances,
              {
                currency: result.currency,
                available: result.available,
                locked: result.freeze,
              },
            ];
          }
          const newBalances = [...prevBalances];
          newBalances[index] = {
            ...newBalances[index],
            available: result.available,
            locked: result.freeze,
          };
          return newBalances;
        });
        
        if (result.change_type === "withdraw") {
          const fetchWithdrawHistory = () => {
            gateService.Wallet.withdrawHistory({
              key: apiKey,
              secret: apiSecret,
            })
              .then(setWithdrawHistory)
              .catch((error) => {
                console.error("Çekim geçmişi alınırken hata oluştu:", error);
              });
          };

          // 10 saniye sonra istek at
          setTimeout(() => {
            fetchWithdrawHistory();
          }, 10000);

          // 1 dakika 10 saniye sonra istek at
          setTimeout(() => {
            fetchWithdrawHistory();
          }, 70000);
        }

        if (result.change_type === "deposit") {
          const fetchDepositHistory = () => {
            gateService.Wallet.depositHistory({
              key: apiKey,
              secret: apiSecret,
            })
              .then(setDepositHistory)
              .catch((error) => {
                console.error("Yatırım geçmişi alınırken hata oluştu:", error);
              });
          };

          // 10 saniye sonra istek at
          setTimeout(() => {
            fetchDepositHistory();
          }, 10000);

          // 1 dakika 10 saniye sonra istek at
          setTimeout(() => {
            fetchDepositHistory();
          }, 70000);
        }
      }

      if (data.event === "update" && data.channel === "spot.orders") {
        const result = data.result[0];
        let timestamp = new Date(result.create_time * 1000);
        let time = timestamp.toISOString().replace("T", " ").slice(0, 19);
        let order = {
          id: result.id,
          createTime: time,
          side: result.side,
          currency_pair: result.currency_pair,
          type: result.type,
          price: result.price,
          amount: result.amount,
          left: result.amount - result.left,
          total: result.filled_total,
          event: result.event,
        };

        if (result.event === "put") {
          setOrders((prevOrders) => [...prevOrders, order]);
        } else if (result.event === "update") {
          setOrders((prevOrders) => {
            const index = prevOrders.findIndex((o) => o.id === order.id);
            if (index !== -1) {
              const updatedOrders = [...prevOrders];
              updatedOrders[index] = order;
              return updatedOrders;
            }
            return prevOrders;
          });
        } else if (result.event === "finish") {
          setOrders((prevOrders) =>
            prevOrders.filter((o) => o.id !== order.id)
          );
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("Gate Private closed.");
      clearInterval(pingInterval);
      ws.current = null;
    };

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      clearInterval(pingInterval);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]); // isActive buraya eklenmeli

  return { orders, withdrawHistory, depositHistory, balance };
};
