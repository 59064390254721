import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Headers.css";
import {
  Piyasalaricon,
  Cuzdanicon,
  Duyurularicon,
  Profilicon,
  Lighticon,
  Downicon,
} from "../../assets/icons/icon";

import NotificationPopup from "../../popup/NotificationPopup";

import { useApp } from "../../context/AppContext";

const exchanges = {
  Gate: `${process.env.PUBLIC_URL}/GateMedia/full_gate1.svg`,
  Mexc: `${process.env.PUBLIC_URL}/MexcMedia/full-mexc-logo.svg`,
  //Jupiter: `${process.env.PUBLIC_URL}/JupiterMedia/logo-with-text-bright.svg`,
};

const Header = () => {
  const [CustomComponent, setCustomComponent] = useState(null);

  const { setSelectedExchange, selectedExchange } = useApp();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const headerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleEvent = (event) => {
      if (!headerRef.current.contains(event.target)) {
        setShowDropdown(false);
        setCustomComponent(null);
        setShowNotificationPopup(false);
      }
    };

    document.addEventListener("mousedown", handleEvent);
    return () => {
      document.removeEventListener("mousedown", handleEvent);
    };
  }, []);

  useEffect(() => {
    setCustomComponent(null);
    setShowNotificationPopup(false);
  }, [location.pathname]);

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange.name);
    setShowDropdown(false);
  };

  const toggleNotificationPopup = () => {
    setShowNotificationPopup(!showNotificationPopup);
  };

  return (
    <div ref={headerRef} className="header">
      <div className="header-left">
        <Link to="/">
          <img
            className="marka-icon"
            src={exchanges[selectedExchange]}
            alt="Marka Logosu"
          />
        </Link>
        <span
          className="header-choose-exchange"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {Downicon}
        </span>
        {showDropdown && (
          <div className="header-exchange-dropdown">
            {Object.entries(exchanges).map(([name, icon], index) => (
              <div
                key={index}
                onClick={() => handleExchangeSelect({ name, icon })}
              >
                <img src={icon} alt={name} />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="header-right">
        <Link to="/piyasalar" className="header-nav-link mobile-hidden">
          Piyasalar {Piyasalaricon}
        </Link>
        <Link to="/wallet" className="header-nav-link mobile-hidden">
          Cüzdan {Cuzdanicon}
        </Link>
        <span onClick={toggleNotificationPopup} className="header-nav-link">
          <span className="mobile-hidden">Duyurular</span> {Duyurularicon}
        </span>
        <Link to={"/profile"} className="header-nav-link mobile-hidden">
          Profil {Profilicon}
        </Link>

        <button className="light-btn">{Lighticon}</button>

        {showNotificationPopup && (
          <NotificationPopup
            setCustomComponent={setCustomComponent}
            onBack={() => setShowNotificationPopup(false)}
          />
        )}

        {CustomComponent && (
          <CustomComponent onBack={() => setCustomComponent(null)} />
        )}
      </div>
    </div>
  );
};

export default Header;
