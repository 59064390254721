import React, { useState, useEffect } from "react";
import "./KeyRecord.css";
import CustomInput from "../../components/CustomInput/CustomInput";

const WalletRecord = ({ exchange }) => {
  // Input değerleri için state tanımlamaları
  const [ad, setAd] = useState("");
  const [wallet, setWallet] = useState("");

  // Component mount olduğunda localStorage'tan verileri çek
  useEffect(() => {
    const storedAd = localStorage.getItem(`${exchange}-Ad`);
    const storedWallet = localStorage.getItem(`${exchange}-Wallet`);
    if (storedAd) setAd(storedAd);
    if (storedWallet) setWallet(storedWallet);
  }, [exchange]); // Exchange değiştiğinde bu işlemi tekrarla

  // Inputlarda değişiklik olduğunda state'i güncelle
  const handleAdChange = (e) => {
    setAd(e.target.value);
  };

  const handleWalletChange = (e) => {
    setWallet(e.target.value);
  };

  // Kaydet butonunun fonksiyonu
  const handleSave = () => {
    localStorage.setItem(`${exchange}-Ad`, ad);
    localStorage.setItem(`${exchange}-Wallet`, wallet);
  };

  return (
    <div className="keyrecord-container">
      <div><h3 className="keyrecord-title-exchange">{exchange}</h3></div>
      <CustomInput title={"Ad"} value={ad} onChange={handleAdChange} />
      <CustomInput
        title={"Wallet"}
        value={wallet}
        onChange={handleWalletChange}
      />

      <button
        className="keyrecord-saved-button"
        type="submit"
        onClick={handleSave}
      >
        Kaydet
      </button>
    </div>
  );
};

export default WalletRecord;
