// ServiceManager.js

import { Spot as GateSpot } from './GateServices/Spot';
import { Spot as MexcSpot } from './MexcServices/Spot';
import { Spot as JupiterSpot } from './JupiterServices/Spot';

import { Wallet as GateWallet } from './GateServices/Wallet';
import { Wallet as MexcWallet } from './MexcServices/Wallet';
import { Wallet as JupiterWallet } from './JupiterServices/Wallet';

import { Token as GateToken } from './GateServices/Token';
import { Token as MexcToken } from './MexcServices/Token';
import { Token as JupiterToken } from './JupiterServices/Token';

const services = {
    "Gate": {
        Spot: GateSpot,
        Wallet: GateWallet,
        Token: GateToken
    },
    "Mexc": {
        Spot: MexcSpot,
        Wallet: MexcWallet,
        Token: MexcToken
    },
    "Jupiter": {
        Spot: JupiterSpot,
        Wallet: JupiterWallet,
        Token: JupiterToken
    }
};

export const getService = (exchangeName) => {
    return services[exchangeName] ;
};
