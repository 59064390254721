import axios from "axios";

// API URL tanımı
const API_URL = "https://jup.gangue.io/";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API isteklerini yapacak temel fonksiyon
const sendRequest = async (method, path,params = {}, headers = {}) => {
  try {
    const response = await axiosInstance({
      method,
      url: path,
      params,
      headers: {
        "Content-Type": "application/json",
        key: headers.key,
        secret: headers.secret,
      },
    });

    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error.response.data);
    throw error;
  }
};

export const Spot = {
  // Çekim geçmişi
  // headers: { key: 'api-key', secret: 'api-secret' }
  // Örnek Kullanım: Spot.withdrawHistory({ key: 'api-key', secret: 'api-secret' })
  orderHistory: async (currencyPair,headers) => {
    return await sendRequest("GET", "/spot/orderHistory",{ currency_pair: currencyPair }, headers);
  }

};
