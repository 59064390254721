import axios from "axios";

// API URL tanımı
const API_URL = "https://gate.gangue.io/";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Genel API istek fonksiyonu
const sendRequest = async (
  method,
  path,
  data = {},
  params = {},
  headers = {}
) => {
  const config = {
    method: method,
    url: path,
    params: params,
    data: data,
    headers: {
      "Content-Type": "application/json",
      key: headers.key,
      secret: headers.secret,
    },
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching data from ${path}:`,
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const Wallet = {
  // Para çekme işlemi
  // currency: Çekilecek para birimi, örneğin 'BTC'
  // address: Çekilecek adres
  // amount: Çekilecek miktar
  // memo: (isteğe bağlı) Çekme işlemi için memo veya etiket
  // chain: Kullanılacak blockchain, örneğin 'BTC'
  // headers: API kimlik bilgileri içeren obje, örneğin { key: 'api-key', secret: 'api-secret' }
  withdraw: async (params, headers) => {
    //  const params = { currency, address, amount, memo:"", chain };
    return await sendRequest("POST", "/wallet/withdraw", params, {}, headers);
  },

  // Mevduat adresini alma
  // currency: Adresi alınacak para birimi, örneğin 'BTC'
  // headers: API kimlik bilgileri
  depositAddress: async (currency, headers) => {
    const chains = await sendRequest(
      "GET",
      "/wallet/depositAdress",
      {},
      { currency },
      headers
    );

    // Filtreleme işlemi burada yapılıyor
    const filteredChains = chains.filter(
      (chain) =>
        chain.chainName === "SOLANA" ||
        chain.chainName === "Tron-TRC20" ||
        chain.chainName === "BSC-BEP20"
    );

    return filteredChains;
  },
  // Kayıtlı adresleri alma
  // currency: Kayıtlı adreslerin alınacağı para birimi, örneğin 'BTC'
  // headers: API kimlik bilgileri
  savedAddress: async (currency, headers) => {
    const cacheKey = "GatesavedAddressCache";
    const cacheTimestampKey = "GatesavedAddressTimestamp";
    const oneHour = 60 * 60 * 8000; // 1 saat (milisaniye cinsinden)

    // Local storage'dan zaman damgasını ve veriyi al
    const cachedResponse = JSON.parse(localStorage.getItem(cacheKey));
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

    // Eğer cache 1 saatten daha eskiyse veya cache yoksa yeni istek yap
    if (!cachedResponse || !cachedTimestamp || Date.now() - cachedTimestamp > oneHour) {
      const response = await sendRequest(
        "GET",
        "/wallet/savedAddress",
        {},
        { currency: "USDT" },
        headers
      );

      // Yanıtı ve zaman damgasını local storage'a kaydet
      localStorage.setItem(cacheKey, JSON.stringify(response));
      localStorage.setItem(cacheTimestampKey, Date.now().toString());

      // Eğer gelen currency USDT değilse filtreleme yap
      if (currency !== "USDT") {
        const filteredResponse = response.filter((item) => {
          const nameLower = item.name.toLowerCase();
          return nameLower.includes("phantom") || nameLower.includes("solflare");
        });
        return filteredResponse;
      }

      // Eğer currency USDT ise gelen yanıtı döndür
      return response;
    }

    // Eğer cache güncelse local storage'daki veriyi döndür
    const filteredResponse = currency !== "USDT"
      ? cachedResponse.filter((item) => {
          const nameLower = item.name.toLowerCase();
          return nameLower.includes("phantom") || nameLower.includes("solflare");
        })
      : cachedResponse;

    return filteredResponse;
  },

  // Çekim işlemini iptal etme
  // orderId: İptal edilecek çekim işleminin ID'si
  // headers: API kimlik bilgileri
  cancelWithdraw: async (orderId, headers) => {
    return await sendRequest(
      "DELETE",
      `/wallet/cancel_withdraw/${orderId}`,
      {},
      {},
      headers
    );
  },

  // Çekim geçmişini alma
  // headers: API kimlik bilgileri
  withdrawHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/withdrawHistory", {}, {}, headers);
  },

  // Mevduat geçmişini alma
  // headers: API kimlik bilgileri
  depositHistory: async (headers) => {
    return await sendRequest("GET", "/wallet/depositHistory", {}, {}, headers);
  },

  // Toplam bakiyeyi alma
  // headers: API kimlik bilgileri
  totalBalance: async (headers) => {
    return await sendRequest("GET", "/wallet/total_balance", {}, {}, headers);
  },

  // Hesap bakiyesini alma
  // headers: API kimlik bilgileri
  accountBalance: async (headers) => {
    return await sendRequest("GET", "wallet/accountBalance", {}, {}, headers);
  },
  onChain: async (currency, headers) => {
    const chains = await sendRequest(
      "GET",
      "/wallet/tokenInfo",
      {},
      { currency },
      headers
    );

    // Filtreleme işlemi burada yapılıyor
    const filteredChains = chains.filter(
      (chain) =>
        chain.is_deposit_disabled === 0 &&
        chain.is_withdraw_disabled === 0 &&
        (chain.chainName === "SOLANA" ||
          chain.chainName === "Tron-TRC20" ||
          chain.chainName === "BSC-BEP20")
    );

    return filteredChains;
  },
  syncContractAddress: async (currency, headers) => {
    const chains = await sendRequest(
      "GET",
      "/wallet/tokenInfo",
      {},
      { currency },
      headers
    );

    const solChain = chains.find((chain) => chain.chain === "SOL");
    return solChain;
  },
};
