import React from "react";
import defaultStyles from "./ArbitrageTable.module.css";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContext";

const ArbitrageTable = ({
  data,
  styles = defaultStyles,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
}) => {
  const navigate = useNavigate();

  const {
    apiKey,
    apiSecret,
    defaultSymbol,
    defaultSymbolV2,
    setTradeToken,
    setSelectedExchange,
  } = useApp();

  const handleRowClick = (row, rowIndex) => {
    if (apiKey && apiSecret) {
      let tradeTokenData = {};
      let exchange = "";
      let currency = "";
  
      if (row.stockA === "Jupiter") {
        tradeTokenData = {
          currencyA: row.currencyB,
          stockA: row.stockB,
          buyPrice: row.sellPrice,
          buyVolume: Math.min(row.buyVolume, row.sellVolume),
          amount: parseInt(Math.min(row.buyVolume, row.sellVolume) * row.buyPrice * Math.pow(10, 6)),
          logoURI: row.logoURI,
          contractAddress: row.contractAddress,
          jup: "buy"
        };
        exchange = row.stockB;
        currency = row.currencyB;
      } else {
        tradeTokenData = {
          currencyA: row.currencyA,
          stockA: row.stockA,
          sellPrice: row.buyPrice,
          sellVolume: Math.min(row.buyVolume, row.sellVolume),
          amount: parseInt(Math.min(row.buyVolume, row.sellVolume) * Math.pow(10, row.decimals)),
          logoURI: row.logoURI,
          contractAddress: row.contractAddress,
          jup: "sell"
        };
        exchange = row.stockA;
        currency = row.currencyA;
      }
  
      setTradeToken(tradeTokenData);
      setSelectedExchange(exchange);
  
      // localStorage'dan Mexc-APIV2-Cookie ve Mexc-APIV2-Mh değerlerini kontrol et
      const hasMexcAPIV2Cookie = localStorage.getItem("Mexc-APIV2-Cookie");
      const hasMexcAPIV2Mh = localStorage.getItem("Mexc-APIV2-Mh");
  
      if (row.stockA === "Mexc") {
        if (hasMexcAPIV2Cookie && hasMexcAPIV2Mh) {
          // defaultSymbolV2'de kontrol
          const isCurrencyInDefaultSymbolV2 = defaultSymbolV2.some(
            (item) => item.currency === row.currencyA
          );
          if (!isCurrencyInDefaultSymbolV2) {
            window.open(`https://www.mexc.com/tr-TR/exchange/${row.currencyA}_USDT`, '_blank');
          }
        } else {
          // defaultSymbol'de kontrol (currency + "_USDT" şeklinde kontrol)
          if (!defaultSymbol.hasOwnProperty(`${row.currencyA}_USDT`)) {
            window.open(`https://www.mexc.com/tr-TR/exchange/${row.currencyA}_USDT`, '_blank');
          }
        }
      }
      
      if (row.stockB === "Mexc") {
        if (hasMexcAPIV2Cookie && hasMexcAPIV2Mh) {
          // defaultSymbolV2'de kontrol
          const isCurrencyInDefaultSymbolV2 = defaultSymbolV2.some(
            (item) => item.currency === row.currencyB
          );
          if (!isCurrencyInDefaultSymbolV2) {
            window.open(`https://www.mexc.com/tr-TR/exchange/${row.currencyB}_USDT`, '_blank');
          }
        } else {
          // defaultSymbol'de kontrol (currency + "_USDT" şeklinde kontrol)
          if (!defaultSymbol.hasOwnProperty(`${row.currencyB}_USDT`)) {
            window.open(`https://www.mexc.com/tr-TR/exchange/${row.currencyB}_USDT`, '_blank');
          }
        }
      }
  
      console.log("Tıklanan satırın verileri:", row);
      navigate(
        `/trading/${exchange}/${currency}_USDT?` +
        new URLSearchParams(tradeTokenData).toString()
      );
    }
  };
  

  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <div className={styles.tr}>
          <span className={`${styles.th} ${styles.mobile_hidden}`}>Coin</span>
          <span className={styles.th}>Buy</span>
          <span className={styles.th}>Sell</span>
          <span className={styles.th}>Volume</span>
          <span className={styles.th}>Profit</span>
        </div>
      </div>
      <div className={styles.tbody}>
        {data.length > 0
          ? data.map((row, rowIndex) => (
              <div
                className={styles.tr}
                key={rowIndex}
                onClick={() => handleRowClick(row, rowIndex)}
              >
                <span className={`${styles.td} ${styles.mobile_hidden} `}>
                  <strong className={`${styles.mobile_hidden}`}>{row.currencyA} - {row.currencyB} </strong>
                  <br />
                  <strong className={`${styles.mobile_hidden}`}>({parseFloat(row.buyPrice * row.buyVolume).toFixed(0)}$) -  ({parseFloat(row.sellPrice * row.sellVolume).toFixed(0)}$) </strong>
                </span>
                <span className={`${styles.td} `}>
                  <span className={`${styles.td} ${styles.negatif}`}>
                    {row.stockA}
                  </span>
                  <br />
                  <strong className={`${styles.mobile_show}`}>{row.currencyA} </strong>
                  <br />
                  <span className={`${styles.td} ${styles.negatif}`}>
                    {row.buyPrice}
                  </span>
                </span>
                <span className={`${styles.td} `}>
                  <span className={`${styles.td} ${styles.pozitif}`}>
                    {row.stockB}
                  </span>
                  <br />
                  <strong className={`${styles.mobile_show}`}>{row.currencyB}  </strong>
                  <br />
                  <span className={`${styles.td} ${styles.pozitif}`}>
                    {row.sellPrice}
                  </span>
                </span>
                <span className={styles.td}>
                  {Math.min(row.buyVolume, row.sellVolume) }
                  <br />
                  ({(Math.min(row.buyVolume, row.sellVolume) * Math.min(row.buyPrice, row.sellPrice)).toFixed(0) } $)
                </span>
                <span
                  className={`${styles.td} ${
                    parseFloat(row.profit) > 0 ? styles.pozitif : styles.negatif
                  }`}
                >
                  {parseFloat(row.profit).toFixed(4)}
                </span>
              </div>
            ))
          : noRowsContent}
      </div>
    </div>
  );
};

export default ArbitrageTable;
