import React, { useState } from "react";
import useEmailVerification from "../../hooks/useEmailVerification"; // Hook'unuzu import edin
import "./Login.css";
import { LoginLefticon } from "../../assets/icons/icon";
import StepIndicator from "../../layout/StepIndicator/StepIndicator";
import OtpInput from "../../components/OtpInput/OtpInput";

const Login = () => {
  const { isOtpSent, sendEmail, verifyOTP, setEmail, email } = useEmailVerification();
  const [otp, setOtp] = useState(""); // OtpInput'tan alınan OTP değeri için state

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    await sendEmail(email);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const response = await verifyOTP(otp);
    if (response && response.token) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("email", email);
      localStorage.setItem("tokenLastChecked", Date.now().toString());
      window.location.href = "/"; // Başarılı OTP doğrulamasından sonra anasayfaya yönlendir
    }
  };

  const data = [{ text: "Mail gir." }, { text: "Mail Doğrula" }]; // Varsayılan değerler
  return (
    <div className="auth-container">
      <aside className="auth-aside">
        <div className="aside-top">{LoginLefticon}</div>
        <div className="aside-middle">
          <div className="middle-box"></div>
        </div>
      </aside>
      <main className="auth-main">
        <h1 className="main-title">
          EKIP’ <span className="title-highlight">ya giriş yapın</span>
        </h1>
        
        {!isOtpSent ? (
          <div className="email-field">
            <label htmlFor="email" className="field-label">Mail</label>
            <input
              className="field-input"
              type="email"
              id="email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        ) : (
          <div className="email-field">
            <OtpInput value={otp} setValue={setOtp} />
          </div>
        )}
        {!isOtpSent ? (
          <button onClick={handleEmailSubmit} className="auth-button">Giriş Yap</button>
        ) : (
          <button onClick={handleOtpSubmit} className="auth-button">Doğrula</button>
        )}
      </main>
      
      <StepIndicator data={data} activeIndex={!isOtpSent ? 0 : 1}/>
    </div>
  );
};

export default Login;
