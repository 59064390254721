import React from 'react';
import { useApp } from '../context/AppContext';

const AlarmPopup = ({ setIsAlarmPopupOpen }) => {
  const {
    notificationValue,
    setNotificationValue,
    soundAlarmValue,
    setSoundAlarmValue,
    setSoundAlarmActive,
    setNotificationAlarmActive,
    stopSoundAlarm // stopSoundAlarm fonksiyonunu ekleyin
  } = useApp();

  const handleNotificationValueChange = (event) => {
    setNotificationValue(event.target.value);
  };

  const handleSoundAlarmValueChange = (event) => {
    setSoundAlarmValue(event.target.value);
  };

  const handleStartSoundAlarm = () => {
    setSoundAlarmActive(true);
  };

  const handleStopSoundAlarm = () => {
    setSoundAlarmActive(false);
    stopSoundAlarm(); // Alarmı durdur
  };

  const handleStartNotificationAlarm = () => {
    setNotificationAlarmActive(true);
  };

  const handleStopNotificationAlarm = () => {
    setNotificationAlarmActive(false);
  };

  return (
    <div style={popupStyle}>
      <h2>Ayarları Düzenle</h2>
      <div style={inputGroupStyle}>
        <label>Bildirim Değeri:</label>
        <input
          type="text"
          value={notificationValue}
          onChange={handleNotificationValueChange}
          style={inputStyle}
        />
        <div style={buttonGroupStyle}>
          <button onClick={handleStartNotificationAlarm} style={buttonStyle}>Başlat</button>
          <button onClick={handleStopNotificationAlarm} style={buttonStyle}>Durdur</button>
        </div>
      </div>
      <div style={inputGroupStyle}>
        <label>Sesli Alarm Değeri:</label>
        <input
          type="text"
          value={soundAlarmValue}
          onChange={handleSoundAlarmValueChange}
          style={inputStyle}
        />
        <div style={buttonGroupStyle}>
          <button onClick={handleStartSoundAlarm} style={buttonStyle}>Başlat</button>
          <button onClick={handleStopSoundAlarm} style={buttonStyle}>Durdur</button>
        </div>
      </div>
    </div>
  );
};

const popupStyle = {
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '20px',
  width: '300px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

const inputGroupStyle = {
  marginBottom: '10px',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  boxSizing: 'border-box',
  marginTop: '5px',
};

const buttonGroupStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
};

const buttonStyle = {
  backgroundColor: '#007BFF',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginRight: '10px',
};

export default AlarmPopup;
