import React, { useEffect, useState } from "react";
import defaultStyles from "./OrderHistoryTable.module.css";
import { useApp } from "../../context/AppContext";

const OrderHistoryTable = ({
  styles = defaultStyles,
  onRowClick,
  noDataContent = <div>No data available</div>,
  noRowsContent = <div>No rows to display</div>,
}) => {
  
  const { service, apiKey, apiSecret, tradeToken } = useApp();
  const [orderHistory, setOrderHistory] = useState([]);
  useEffect(() => {
    service.Spot.orderHistory(tradeToken.currencyA + "_USDT", {
      key: apiKey,
      secret: apiSecret,
    })
      .then(setOrderHistory)
      .catch((error) => {
        console.error("Token bilgileri alınırken hata oluştu:", error);
      });


  }, [service,apiKey,apiSecret,tradeToken.currencyA]); // Yalnızca service değiştiğinde çalışır
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString("tr-TR");
    const timePart = date.toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${datePart} \n ${timePart}`;
  };
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        
      </div>
      <div className={styles.tbody}>
      <div className={styles.tr}>
          <span className={styles.th}>Zaman</span>
          <span className={styles.th}>Piyasa</span>
          <span className={styles.th}>Fiyat</span>
          <span className={styles.th}>Emir Miktarı / Toplam (USDT)</span>
          <span className={styles.th}>Durum</span>
        </div>
        {orderHistory.length > 0
          ? orderHistory.map((row, rowIndex) => (
              <div className={styles.tr} key={rowIndex}>
                <span className={styles.td}>{formatDate(row.createTime)}</span>
                <span className={styles.td}>{row.currency_pair}</span>
                <span className={`${styles.td} ${ row.side ==="buy" ? styles.pozitif : styles.negatif}`}  title={row.price}>
                  {row.price}
                </span>
                <span className={styles.td}>{row.amount} / {parseFloat(row.total).toFixed(4)}</span>
                <span className={styles.td}>{row.event}</span>
              </div>
            ))
          : (
            <div className={styles.tr} >
              <span className={styles.td}>İşlem geçmişi yok</span>
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderHistoryTable;
