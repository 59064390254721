import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Payload oluşturma fonksiyonu
export const generatePayload = (channel, event, payload) => {
  const timestamp = Math.floor(Date.now() / 1000);
  return JSON.stringify({
    time: timestamp,
    channel: channel,
    event: event,
    payload: payload,
  });
};

export const useGatePublicWebsocket = (isActive) => {
  const ws = useRef(null);
  const [tickers, setTickers] = useState([]);
  const [publicTrades, setPublicTrades] = useState([]);
  const [orderBook, setOrderBook] = useState([]);
  const [searchParams] = useSearchParams();
  const initialCurrencyRef = useRef(null);
  const initialUnsubscribedRef = useRef(false);
  const [stockA, setStockA] = useState(null);

  useEffect(() => {
    // URL'den stockA'yı alıyoruz ve state'e kaydediyoruz
    const stockParam = searchParams.get("stockA");
    setStockA(stockParam);
  }, [searchParams]);

  // Mesaj gönderme fonksiyonu
  const sendGateSocketMessage = useCallback((channel, event, payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      // İlk abonelikleri bir kez iptal et
      if (!initialUnsubscribedRef.current) {
        const initialCurrency = initialCurrencyRef.current;
        if (initialCurrency) {
          ws.current.send(
            generatePayload("spot.tickers", "unsubscribe", [initialCurrency])
          );
          ws.current.send(
            generatePayload("spot.trades", "unsubscribe", [initialCurrency])
          );
          ws.current.send(
            generatePayload("spot.order_book", "unsubscribe", [
              initialCurrency,
              "10",
              "1000ms",
            ])
          );
        }
        initialUnsubscribedRef.current = true;
      }
      const message = generatePayload(channel, event, payload);
      ws.current.send(message);
    }
  }, []);

  useEffect(() => {
    let pingInterval;
    let socket; // Yerel değişken

    if (!isActive) {
      return;
    }

    // URL'den currencyA ve stockA'yı alıyoruz
    const currencyParam = searchParams.get("currencyA");
    const stockParam = stockA; // Güncel stockA değerini kullanıyoruz

    // Eğer stockA "Gate" ise ve currencyA mevcutsa abonelikleri başlat
    if (stockParam === "Gate" && currencyParam) {
      const initialCurrency = currencyParam + "_USDT";
      initialCurrencyRef.current = initialCurrency;

      socket = new WebSocket("wss://api.gateio.ws/ws/v4/");
      ws.current = socket; // ws.current'e atama yapıyoruz

      socket.onopen = () => {
        console.log("Gate Public connection.");

        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }

        // İlk abonelikleri başlat
        socket.send(
          generatePayload("spot.tickers", "subscribe", [initialCurrency])
        );
        socket.send(
          generatePayload("spot.trades", "subscribe", [initialCurrency])
        );
        socket.send(
          generatePayload("spot.order_book", "subscribe", [
            initialCurrency,
            "10",
            "1000ms",
          ])
        );

        // Ping interval
        pingInterval = setInterval(() => {
          socket.send(generatePayload("spot.ping", "ping", {}));
        }, 30 * 1000); // 30 saniye
      };

      socket.onmessage = (event) => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }

        const data = JSON.parse(event.data);

        if (data.event === "update" && data.channel === "spot.tickers") {
          let newData = {
            cp: "currency_pair",
            r: data.result.change_percentage,
            p: data.result.last,
            l: data.result.low_24h,
            h: data.result.high_24h,
            v: data.result.base_volume,
            q: data.result.quote_volume,
          };
          setTickers(newData);
        }
        if (data.event === "update" && data.channel === "spot.trades") {
          let newData = {
            t: data.result.create_time * 1000,
            cp: "currency_pair",
            s: data.result.side,
            p: data.result.price,
            v: data.result.amount,
          };
          setPublicTrades((prevTrades) => {
            const newTrades = [newData, ...prevTrades];
            // Listeyi 20 öğe ile sınırla
            return newTrades.slice(0, 20);
          });
        }
        if (data.event === "update" && data.channel === "spot.order_book") {
          let asks = [];
          let bids = [];

          let buyTotalVolume = 0;
          let buyTotalVolumeArray = [];
          let sellTotalVolume = 0;

          for (let i = 0; i < data.result.asks.length; i++) {
            const buyPrice = Number(data.result.asks[i][0]);
            const buyVolume = Number(data.result.asks[i][1]);
            buyTotalVolume += buyVolume;
            buyTotalVolumeArray.push(buyTotalVolume);
            asks.push({
              p: buyPrice,
              v: buyVolume,
              tv: buyTotalVolumeArray[i],
            });
          }

          for (let i = 0; i < data.result.bids.length; i++) {
            const sellPrice = Number(data.result.bids[i][0]);
            const sellVolume = Number(data.result.bids[i][1]);
            sellTotalVolume += sellVolume;
            bids.push({
              p: sellPrice,
              v: sellVolume,
              tv: sellTotalVolume,
            });
          }

          const updatedOrderBook = {
            cp: "currency_pair",
            asks: asks,
            bids: bids,
          };

          setOrderBook(updatedOrderBook);
        }
      };

      socket.onerror = (error) => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        if (ws.current !== socket) {
          // Bu socket artık geçerli değil; işlemi durdur
          return;
        }
        console.log("Gate Public closed.");
        clearInterval(pingInterval);
        ws.current = null;
      };
    } else {
      // stockA "Gate" değilse, mevcut bağlantıyı kapat
      if (ws.current) {
        ws.current.close();
        ws.current = null;
        initialUnsubscribedRef.current = false; // İlk iptal bayrağını sıfırla
        initialCurrencyRef.current = null;
        console.log("Gate Public connection closed due to stockA change.");
      }
      // State'i sıfırla
      setTickers([]);
      setPublicTrades([]);
      setOrderBook([]);
    }

    return () => {
      if (ws.current === socket && ws.current) {
        ws.current.close();
        ws.current = null;
      }
      clearInterval(pingInterval);
    };
  }, [isActive, stockA, searchParams]);

  return { tickers, publicTrades, orderBook, sendGateSocketMessage };
};
