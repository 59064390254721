import React, { useState, useEffect } from 'react';

const OtpInput = ({ value = '', setValue }) => {
  const initialValues = value ? Array(6).fill('').map((_, index) => value[index] || '') : Array(6).fill('');
  const [inputs, setInputs] = useState(initialValues);

  useEffect(() => {
    if (setValue) {
      setValue(inputs.join(''));
    }
  }, [inputs, setValue]);

  const handleChange = (index, val) => {
    const newInputs = [...inputs];
    newInputs[index] = val[0] || ''; // Sadece ilk karakteri al veya boş bırak
    setInputs(newInputs);

    // Otomatik olarak bir sonraki input'a focus yap
    if (val[0] && index < 5) {
      document.getElementById(`input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && inputs[index] === '' && index > 0) {
      // Önceki input'a focus yap ve mevcut değeri sil
      e.preventDefault();
      const newInputs = [...inputs];
      newInputs[index - 1] = ''; // Önceki input'un değerini sil
      setInputs(newInputs);
      document.getElementById(`input-${index - 1}`).focus();
    }
  };

  const handlePaste = (e, startIndex) => {
    e.preventDefault();
    const pasteContent = e.clipboardData.getData('text');
    // Yapıştırılan içeriğin sadece sayı olduğunu ve maksimum 6 karakter uzunluğunda olduğunu doğrula
    if (!pasteContent.match(/^\d{1,6}$/)) {
      // Eğer koşullar karşılanmıyorsa, yapıştırma işlemini engelle
      return;
    }
    
    // Başlangıç indeksine göre yapıştırılacak maksimum karakter sayısını hesapla
    const maxPasteLength = inputs.length - startIndex;
    const paste = pasteContent.slice(0, maxPasteLength);
    
    const newInputs = [...inputs];
    // Yapıştırılan içeriği uygun input kutucuklarına dağıt
    paste.split('').forEach((char, index) => {
      newInputs[startIndex + index] = char;
    });
    setInputs(newInputs);
  
    // Yapıştırma işlemi sonrası focus yönetimi
    const nextIndex = startIndex + paste.length;
    if (nextIndex < inputs.length) {
      document.getElementById(`input-${nextIndex}`).focus();
    }
  };
  

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '360px', margin: '20px auto', gap: '10px' }}>
      {inputs.map((input, index) => (
        <input
          key={index}
          id={`input-${index}`}
          type="text"
          inputMode="numeric"
          maxLength="1"
          value={input}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          style={{ width: '25px', height: '25px', textAlign: 'center', fontSize: '20px' }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
